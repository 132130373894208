import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WaveformGenerator } from '../waveformGenerator/waveformgenerator';
import { throttle } from "lodash";

const Audio = ({ AudioUrl, ticketId, config, transcription, showDualWave, isLoading, setCurrAudioTime, currAudioTime, subParamTime }) => {
    const userRole = useSelector(state => state.auth.loggedInUser.role)
    const [totalSeconds, setTotalSeconds] = useState(0);
    const [totalBars, setTotalBars] = useState(280);
    const [currTime, setCurrTime] = useState(currAudioTime || 0 );
    const lastCurrTimeRef = useRef(0);
    const transRef = useRef(transcription?.[0]);
    let flagRef = useRef(true);
    const audioRef = useRef(null);  
    const onAudioReady = e => setTotalSeconds(e.target.duration);
    const onTimeChange = (e) => {
        setCurrTime(e.target.currentTime);
        onAudioTimeUpdate(e)
    };
    
     useEffect(() => {
        if(subParamTime?.start){
            setCurrTime(subParamTime?.start);
            audioRef.current.currentTime = subParamTime?.start;
        }
     }, [subParamTime]);

    const updateCurrentTime = t => (audioRef.current.currentTime = t);

    const adjustTimesWithoutChangingCurrent = (arr) => {
        return arr.map((item, index, array) => {

          if (index === 0) return null;
      
          return {
            startTime: (array[index - 1].endTime + 1)?.toFixed(2), 
            endTime: (array[index ].startTime - 1)?.toFixed(2)
          };
        }).filter(Boolean);
      };
     
    const overAllData = transcription?.filter((el) => el.speaker === transcription?.[0]?.speaker)?.map(({ start, end }) => ({ startTime: start, endTime: end }));
    let barTimelinesOne = [];
    let barTimelinesTwo = [];
    if (transcription?.[0]?.speaker === "Agent") {
        barTimelinesOne = overAllData
        barTimelinesTwo = adjustTimesWithoutChangingCurrent(overAllData);
    } else {
        barTimelinesOne = adjustTimesWithoutChangingCurrent(overAllData);
        barTimelinesTwo = overAllData
    }

    const calculateLinePosition = () => {
        if (!totalSeconds) return 0;
        return (currTime / totalSeconds) * 100;
    };

    function findActiveIndex(transcription, currTime) {
        for (let i = 0; i < transcription.length; i++) {
            const { start, end } = transcription[i];
            if (currTime >= start && currTime <= end) {
                transRef.current = transcription[i]
                return {index: i,transcription: transcription[i]}
            }
        }
        return {index: -1,transcription: transcription[0]};
    }

    const handleTimeUpdate = throttle(audioTime => {
        const buffer = 0; 
        const { current: lastTime } = lastCurrTimeRef;

        if (Math.abs(audioTime - lastTime) > buffer) {
            lastCurrTimeRef.current = audioTime;
            const d = findActiveIndex(transcription, currTime);
            if(currTime>=transRef?.current?.start && currTime<transRef?.current?.end){
                if(flagRef.current){
                    setCurrAudioTime(audioTime);
                    flagRef.current = false
                }
            }else {
                setCurrAudioTime(audioTime);
                flagRef.current = true
            }
        }
    }, 500);

    const onAudioTimeUpdate = event => {
        const audioTime = event.target.currentTime;
        handleTimeUpdate(audioTime);
    };
        
    return (
        <div>
            {showDualWave && !isLoading && (
                <div
                    className='wave_line_wrap'
                    style={{ display: 'flex', textAlign: 'right' }}
                >
                    <div className='wave_left_false'></div>
                    <div className='wave_left'>
                        <p className='mb-0 call_agent1'>Agent</p>
                        <p className='mb-0 call_agent2'>Customer</p>
                    </div>

                    <div className='wave_right'>
                        <WaveformGenerator
                            currTime={currTime}
                            totalSeconds={totalSeconds}
                            totalBars={totalBars}
                            onClick={updateCurrentTime}
                            barTimelinesOne={barTimelinesOne}
                            showDualWave={showDualWave}
                        />
                        <br />
                        <WaveformGenerator
                            currTime={currTime}
                            totalSeconds={totalSeconds}
                            totalBars={totalBars}
                            onClick={updateCurrentTime}
                            barTimelinesTwo={barTimelinesTwo}
                            showDualWave={showDualWave}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                left: `${calculateLinePosition()}%`,
                                top: '0',
                                bottom: '0',
                                width: '2px',
                                backgroundColor: 'green',
                            }}
                        ></div>
                    </div>
                    <div className='wave_right_false'></div>
                </div>
            )}

            {!showDualWave && !isLoading && (
                <WaveformGenerator
                    currTime={currTime}
                    totalSeconds={totalSeconds}
                    totalBars={totalBars}
                    onClick={updateCurrentTime}
                    barTimelinesOne={barTimelinesOne}
                    showDualWave={showDualWave}
                />
            )}
            
            {config?.type == 'urlpopup' ? (
                <div
                    className="audiourl"
                    onClick={() => {
                        const newWindow = window.open("", ticketId, "width=450,height=300,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no");
                        if (newWindow) {
                            newWindow.document.write(`
                                <html>
                                    <head>
                                        <title>${AudioUrl}</title>
                                    </head>
                                    <body style="display: flex; align-items: center; justify-content: center; height: 100vh; margin: 0;">
                                        <audio
                                            controls
                                            controlsList="nodownload"
                                            src="${AudioUrl}"
                                            preload="auto"
                                            oncontextmenu="return false"
                                        ></audio>
                                    </body>
                                </html>
                            `);
                            newWindow.document.close();
                        }
                    }}
                >
                    <svg
                        width="35"
                        height="34"
                        viewBox="0 0 35 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.125 24.8126L25.375 17.407L13.125 10.0015V24.8126ZM17.5 33.8639C15.0792 33.8639 12.8042 33.4319 10.675 32.5679C8.54583 31.7039 6.69375 30.5314 5.11875 29.0503C3.54375 27.5692 2.29688 25.8275 1.37813 23.8252C0.459375 21.823 0 19.6836 0 17.407C0 15.1305 0.459375 12.9911 1.37813 10.9889C2.29688 8.98663 3.54375 7.24494 5.11875 5.76382C6.69375 4.28271 8.54583 3.11016 10.675 2.24617C12.8042 1.38219 15.0792 0.950195 17.5 0.950195C19.9208 0.950195 22.1958 1.38219 24.325 2.24617C26.4542 3.11016 28.3063 4.28271 29.8813 5.76382C31.4563 7.24494 32.7031 8.98663 33.6219 10.9889C34.5406 12.9911 35 15.1305 35 17.407C35 19.6836 34.5406 21.823 33.6219 23.8252C32.7031 25.8275 31.4563 27.5692 29.8813 29.0503C28.3063 30.5314 26.4542 31.7039 24.325 32.5679C22.1958 33.4319 19.9208 33.8639 17.5 33.8639Z"
                            fill="#117DE6"
                        />
                    </svg>
                    <div>{AudioUrl}</div>
                </div>
            ) : (
                <audio
                    className='custom-audio-player'
                    ref={audioRef}
                    controls
                    controlsList={userRole === 'Agent' && 'nodownload'}
                    src={AudioUrl}
                    preload='auto'
                    onTimeUpdate={onTimeChange}
                    onCanPlayThrough={onAudioReady}
                    onContextMenu={(e) => {
                        if (userRole === "Agent") e.preventDefault();
                    }} 
                />
            )}
        </div>
    );
};

export default Audio;
