import React, { useState, useRef, useEffect } from 'react';
import { ErrorNotify, delay, OutsideClick, imagePath, successNotify } from '../../CommonLogic';
import { client } from '../../services/apiService';
import ObsStructure from '../Audit/ObsStructure';
import { Form, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import Select from 'react-select';
import { fileTypes } from '../../Constant';
const _ = require('lodash');[]


const AwsForm = () => {
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [awsData, setAwsData] = useState({
        Aws_Access_Key: '',
        Aws_Secret_Key: '',
        Aws_Region: '',
        Aws_Bucket_Name: '',
        Department: '',
        Connection_Status: false
    });
    const [awsDataCopy, setAwsDataCopy] = useState(awsData);
    const [defaultFilter, setDefaultFilter] = useState({
        duration: 20,
        disposition: [],
        durationFilter: {},
        audioType: [],
        auditPercent: "100"
    })
    const [validated, setValidated] = useState(false);
    const obsOpenCloseRef = useRef(false);
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organization = useSelector(AuthSelector.organization);
    const singleClickFunction = useRef(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueCopy, setSelectedValueCopy] = useState('');
    const formatTypeRef = useRef();
    const [fileTypeOptions, setFileTypeOptions] = useState(fileTypes);
    const [selectedFileType, setSelectedFileType] = useState([]);
    const [selectedFileTypeCopy, setSelectedFileTypeCopy] = useState(selectedFileType);
    const [minValue, setMinValue] = useState(null);
    const [minValueCopy, setMinValueCopy] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [maxValueCopy, setMaxValueCopy] = useState(null);
    const [valueChanged, setValueChanged] = useState(false);
    const [awsCredentialId, setAwsCredentialId] = useState('');
    const [disablesaveButton, setDisablesaveButton] = useState(false);
    const selectedValueRef = useRef(selectedValue);
    const [showDropdown, setShowDropdown] = useState(false);
    const [auditValue, setAuditValue] = useState('');
    const [auditValueCopy, setAuditValueCopy] = useState(auditValue);
    const minValueRef = useRef(minValue);
    const maxValueRef = useRef(maxValue);
    const dropdownRef = useRef(null);
    const fetchedNow = useRef(false);
    
    useEffect(() => {
        setMaxValue(selectedValue === "Between" ? 5 : null)
        maxValueRef.current = selectedValue === "Between" ? 5 : null;
        setMinValue(selectedValue ? 2 : null)
        minValueRef.current = selectedValue ? 2 : null;
    }, [selectedValue]);

    useEffect(() => {
        let result1 = areObjectsDifferent(awsData, awsDataCopy);
        let result2 = areArraysEqual(selectedFileType, selectedFileTypeCopy);
        let result3 = areArraysEqual(selectedValue, selectedValueCopy);
        let result4 = areArraysEqual(minValue, minValueCopy);
        let result5 =maxValue ? areArraysEqual(maxValue, maxValueCopy) : false;
        let result6 = areArraysEqual(auditValue, auditValueCopy);
        
        let finalRes = result1 || result2 || result3 || result4 || result5 || result6;
        if(!fetchedNow.current){
            setValueChanged(finalRes);
            setDisablesaveButton(finalRes);
        }
    }, [awsData, selectedFileType, minValue, maxValue, selectedValue, auditValue]);

    const addDisposition = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (
            inputValue.trim() &&
            !defaultFilter.disposition.includes(inputValue.trim())
        ) {
            setDefaultFilter((prev) => ({
                ...prev,
                disposition: [...prev.disposition, inputValue.trim()],
            }));
            setInputValue("");
        }

    };

    const removeDisposition = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        setDefaultFilter((prev) => ({
            ...prev,
            disposition: prev.disposition.filter((_, i) => i !== index),
        }));
    };

    useEffect(() => {
        if (selectedDepartment && selectedDepartment?.Department_Code) {
            FetchCredentials(selectedDepartment.Department_Code);
            fetchDepartmentFilter(selectedDepartment.Department_Code)
        }

    }, [selectedDepartment])

    async function fetchDepartmentFilter(selectedDepartment) {
        try {
            const response = await client.get(`/departments?[filters][Department_Code][$eq]=${selectedDepartment}`);
            if (!response.success) {
                return;
            }
            if (response?.data[0]?.attributes) {
                setDisablesaveButton(false);
                const filter = response.data[0].attributes.Default_Filter;
                setDefaultFilter(filter);
                setSelectedFileType(fileTypes?.filter((el)=>filter?.fileType?.includes(el?.value)) || []);
                setSelectedFileTypeCopy(fileTypes?.filter((el)=>filter?.fileType?.includes(el?.value)))
                setMinValue(filter?.durationFilter?.minValue);
                setMinValueCopy(filter?.durationFilter?.minValue)
                setMaxValue(filter?.durationFilter?.maxValue);
                setMaxValueCopy(filter?.durationFilter?.maxValue)
                minValueRef.current = filter?.durationFilter?.minValue
                maxValueRef.current = filter?.durationFilter?.maxValue
                setSelectedValue(filter?.durationFilter?.type);
                setSelectedValueCopy(filter?.durationFilter?.type);
                setAuditValueCopy(parseInt(filter?.auditPercent));
                setAuditValue(parseInt(filter?.auditPercent));
            }
            fetchedNow.current = true
        } catch (error) {
            console.log("Error fetching Default Filter:", error);
        }
    }

    async function FetchCredentials(selectedDepartment) {

        try {
            const response = await client.get(`/client-credentials?[filters][Department_Code][$eq]=${selectedDepartment}`);

            const data = response?.data?.[0];
            setAwsCredentialId(data?.id);
            fetchedNow.current = true
            setAwsData({
                Aws_Access_Key: data?.Aws_Access_Key ?? "",
                Aws_Secret_Key: data?.Aws_Secret_Key ?? "",
                Aws_Region: data?.Aws_Region ?? "",
                Aws_Bucket_Name: data?.Aws_Bucket_Name ?? "",
                Department: "",
                Connection_Status: data?.Connection_Status || false
            });
            setAwsDataCopy({
                Aws_Access_Key: data?.Aws_Access_Key ?? "",
                Aws_Secret_Key: data?.Aws_Secret_Key ?? "",
                Aws_Region: data?.Aws_Region ?? "",
                Aws_Bucket_Name: data?.Aws_Bucket_Name ?? "",
                Department: "",
                Connection_Status: data?.Connection_Status || false
            });
            
        } catch (error) {
            console.log("Error fetching credentials:", error);
        }
    }

    const handleKeyDown = event => {
        const { name } = event.target;
        if (event.key === 'Backspace' && event.target.value?.includes('*')) {
            setAwsData(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setAwsData(prev => ({ ...prev, [name]: value?.replace(/^\*+/, '') }));
        fetchedNow.current = false
    };

    function areObjectsDifferent(obj1, obj2) {
        return !_.isEqual(obj1, obj2);
      }

      function areArraysEqual(array1, array2) {
        return !_.isEqual(array1, array2);
      }
    const handleSubmit = async (e, submitType) => {
        // if (singleClickFunction.current) {
        //     return;
        // }
        singleClickFunction.current = true;
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget.form;
        if (
            form.checkValidity() == false ||
            !selectedDepartment ||
            !awsData.Aws_Access_Key ||
            !awsData.Aws_Secret_Key ||
            !awsData.Aws_Region ||
            !awsData.Aws_Bucket_Name
        ) {
            e.preventDefault();
            e.stopPropagation();
            return true;
        }
        let payloadData = { ...awsData };
        payloadData.Department = selectedDepartment?.value;
        payloadData.Department_Code = selectedDepartment?.Department_Code;
        payloadData.Department_Name = selectedDepartment?.label;
        payloadData.Client_Name = organization?.organisationName ? organization.organisationName : ""

        try {
            let payloadDataUpdate = {};
            if (!awsData?.Aws_Access_Key?.includes('*')) {
                payloadDataUpdate.Aws_Access_Key = awsData?.Aws_Access_Key;
            }
            if (!awsData?.Aws_Secret_Key?.includes('*')) {
                payloadDataUpdate.Aws_Secret_Key = awsData?.Aws_Secret_Key;
            }
            if (!awsData?.Aws_Region?.includes('*')) {
                payloadDataUpdate.Aws_Region = awsData?.Aws_Region;
            }
            if (!awsData?.Aws_Bucket_Name?.includes('*')) {
                payloadDataUpdate.Aws_Bucket_Name = awsData?.Aws_Bucket_Name;
            }
            if (submitType != 'Update') {
                const response = await client.post('/client-credentials', {
                    data: payloadData,
                });
                await FetchCredentials(selectedDepartment?.Department_Code);
                successNotify("AWS Credentials submitted successfully!")
            } else {
                const result = areObjectsDifferent(awsData, awsDataCopy);
                if (result) {
                    const response = await client.put(
                        `/client-credentials/${awsCredentialId}`,
                        {
                            data: payloadDataUpdate,
                        }
                    );
                    successNotify("Changes updated successfully!");
                    await FetchCredentials(selectedDepartment?.Department_Code);
                }
            }
            let result2 = areArraysEqual(selectedFileType, selectedFileTypeCopy);
            let result3 = areArraysEqual(selectedValue, selectedValueCopy);
            let result4 = areArraysEqual(minValue, minValueCopy);
            let result5 = maxValue
                ? areArraysEqual(maxValue, maxValueCopy)
                : false;
                let result6 = areArraysEqual(auditValue, auditValueCopy);
            let finalRes = result2 || result3 || result4 || result5 || result6;
            
            if (submitType != 'Update' || finalRes) {
                const res = await client.put(
                    `/departments/${selectedDepartment?.value}`,
                    {
                        data: {
                            Default_Filter: {
                                duration: defaultFilter?.duration || 20,
                                disposition: defaultFilter?.disposition || [],
                                fileType:
                                    selectedFileType?.map(el => el.value) ||
                                    defaultFilter?.fileType,
                                durationFilter: {
                                    type:
                                        selectedValue ||
                                        defaultFilter?.durationFilter?.type,
                                    minValue:
                                        minValue ||
                                        defaultFilter?.durationFilter?.minValue,
                                    maxValue:
                                        maxValue ||
                                        defaultFilter?.durationFilter?.maxValue,
                                },
                                auditPercent: auditValue || '100'
                            },
                        },
                    }
                );
                fetchDepartmentFilter(selectedDepartment.Department_Code)
                if(submitType == 'Update' ){
                    successNotify("Changes updated successfully!")
                }
            }
            setDisablesaveButton(false);
        } catch (error) {
            console.log(error, 'Err');
        }
    };

    const CustomFormControl = React.forwardRef(({ name, isNotValid }, ref) => {
        return (
            <ObsStructure
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
                selectPlaceholder={true}
                id={isNotValid ? 'obsParam' : ''}
            />
        );
    });

    const handleOutsideClick = async () => {
        if (obsOpenCloseRef.current == true) {
            obsOpenCloseRef.current = false;
            await delay(300);
        }
    };

    const handleClose = () => {
        setAwsData({
            Aws_Access_Key: '',
            Aws_Secret_Key: '',
            Aws_Region: '',
            Aws_Bucket_Name: '',
            Department: '',
        });
        setValidated(false)
    };

    useEffect(() => {
        if (!selectedDepartment?.value) {
            handleClose()
        }

    }, [selectedDepartment?.value]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSelect = value => {
        setSelectedValue(value);
        selectedValueRef.current = value;
        fetchedNow.current = false
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedValue]);

    const handleMinChanges = e => {
        minValueRef.current = e.target.value;
        setMinValue(e.target.value);
    };

    const handleMaxChanges = e => {
        maxValueRef.current = e.target.value;
        setMaxValue(e.target.value);
    };

    const handleFormatType = selectedOption => {
        setSelectedFileType(selectedOption);
        fetchedNow.current = false
    };

    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='content_wrap_style'>
                        <div className='content_wrap_comman'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='breadcrumb_box'>
                                        <Breadcrumb>
                                            <Breadcrumb.Item as='li' href='/'>
                                                <img
                                                    src={imagePath(
                                                        '/images/home_icon_pagig.svg'
                                                    )}
                                                />
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                as='li'
                                                linkAs={Link}
                                                linkProps={{
                                                    to: `/o/${orgStrId}/setup`,
                                                }}
                                                aria-current='page'>
                                                Settings
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                as='li'
                                                active
                                                aria-current='page'>
                                                aws-integration
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                </div>
                            </div>
                            <div className='page_title_box two_column_title'>
                                <h3 className='page_title'>AWS Credentials</h3>
                                {awsData?.Connection_Status && (
                                    <div
                                        style={{
                                            display: 'block',
                                            width: '45%',
                                            marginTop: '-30px',
                                            color: 'rgb(183, 194, 194)',
                                        }}>
                                        <span>
                                            We are initiating the connection
                                            with your server. Once the
                                            connection is successfully
                                            established,{' '}
                                        </span>
                                        <span>
                                            {' '}
                                            we will begin auditing the qualified
                                            calls at the scheduled intervals{' '}
                                        </span>
                                    </div>
                                )}
                                <div
                                    className='form_style_comman'
                                    style={{ width: '32%' }}>
                                    <label>OBS</label>
                                    <ObsStructure
                                        selectedDepartment={selectedDepartment}
                                        setSelectedDepartment={
                                            setSelectedDepartment
                                        }
                                        selectPlaceholder={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <Form
                                    validated={validated}
                                    className='form_style_comman'>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <Form.Group className='form-group'>
                                                <Form.Label>
                                                    Access Key ID
                                                    <sup className='sup_manatory text-danger'>
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    className='hg-prm-control'
                                                    name='Aws_Access_Key'
                                                    value={
                                                        awsData.Aws_Access_Key
                                                    }
                                                    onChange={handleChange}
                                                    required
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    Access Key ID is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-4'>
                                            <Form.Group className='form-group'>
                                                <Form.Label>
                                                    Secret Access Key
                                                    <sup className='sup_manatory text-danger'>
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    className='hg-prm-control'
                                                    name='Aws_Secret_Key'
                                                    value={
                                                        awsData.Aws_Secret_Key
                                                    }
                                                    onChange={handleChange}
                                                    required
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    Secret Access Key is
                                                    required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-4'>
                                            <Form.Group className='form-group'>
                                                <Form.Label>
                                                    Region
                                                    <sup className='sup_manatory text-danger'>
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    className='hg-prm-control'
                                                    name='Aws_Region'
                                                    value={awsData.Aws_Region}
                                                    onChange={handleChange}
                                                    required
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    Aws_Region is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-4'>
                                            <Form.Group className='form-group'>
                                                <Form.Label>
                                                    Bucket Name
                                                    <sup className='sup_manatory text-danger'>
                                                        *
                                                    </sup>
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    className='hg-prm-control'
                                                    name='Aws_Bucket_Name'
                                                    value={
                                                        awsData.Aws_Bucket_Name
                                                    }
                                                    onChange={handleChange}
                                                    required
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    Bucket Name is required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <h5 className='h5'>Call Filteration</h5>
                                    <div className='row'>
                                        <div className='col-lg-4 form-group'>
                                            <div
                                                ref={dropdownRef}
                                                className='custom_duration_inp_box'>
                                                <label>Call Duration</label>
                                                <input
                                                    type='text'
                                                    className='form-control custom_duration_inp'
                                                    value={selectedValue}
                                                    onClick={toggleDropdown}
                                                    readOnly // Prevent manual input
                                                    placeholder='Select condition'
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                />

                                                {/* Dropdown list */}
                                                {showDropdown && (
                                                    <div className='custom_duration_ddn'>
                                                        <ul
                                                            style={{
                                                                listStyle:
                                                                    'none',
                                                                padding: 0,
                                                                margin: 0,
                                                            }}>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'LessThan'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio1'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'LessThan'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio1'>
                                                                        Less
                                                                        Than
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'LessThanEqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio2'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'LessThanEqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio2'>
                                                                        Less
                                                                        than
                                                                        equal to
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'GreaterThan'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio3'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'GreaterThan'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio3'>
                                                                        Greater
                                                                        Than
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'GreaterThanEqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio4'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'GreaterThanEqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio4'>
                                                                        Greater
                                                                        Than
                                                                        Equals
                                                                        To
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'EqualTo'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio5'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'EqualTo'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio5'>
                                                                        Equals
                                                                        To
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleSelect(
                                                                        'Between'
                                                                    )
                                                                }>
                                                                <Form.Check>
                                                                    <Form.Check.Input
                                                                        type='radio'
                                                                        name='radios'
                                                                        id='exampleRadio6'
                                                                        checked={
                                                                            selectedValue ===
                                                                            'Between'
                                                                        }
                                                                    />
                                                                    <Form.Check.Label htmlFor='exampleRadio6'>
                                                                        Between
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </li>
                                                        </ul>
                                                        {selectedValue ===
                                                        'Between' ? (
                                                            <div className='ddn_value_between_box d-flex align-items-center'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    placeholder='Min Value (mins)'
                                                                    value={
                                                                        minValue
                                                                    }
                                                                    defaultValue={
                                                                        2
                                                                    }
                                                                    onChange={
                                                                        handleMinChanges
                                                                    }
                                                                />
                                                                <div className='ddn_dash_icon'>
                                                                    <img
                                                                        src='/images/dash_icon.svg'
                                                                        alt='dash'
                                                                    />
                                                                </div>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    placeholder='Max Value (mins)'
                                                                    value={
                                                                        maxValue
                                                                    }
                                                                    onChange={
                                                                        handleMaxChanges
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Value (mins)'
                                                                value={minValue}
                                                                onChange={
                                                                    handleMinChanges
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* Format Filter */}
                                        <div
                                            className='col-lg-4 form-group'
                                            ref={formatTypeRef}>
                                            <label htmlFor=''>
                                                Format Type
                                            </label>
                                            <Select
                                                options={fileTypeOptions.map(
                                                    des => ({
                                                        value: des.value,
                                                        label: des?.label,
                                                    })
                                                )}
                                                defaultValue={selectedFileType}
                                                onChange={selectedOption => {
                                                    handleFormatType(
                                                        selectedOption
                                                    );
                                                }}
                                                value={selectedFileType}
                                                isSearchable
                                                placeholder='Select'
                                                isDisabled={
                                                    selectedDepartment?.value
                                                        ? false
                                                        : true
                                                }
                                                isMulti
                                            />
                                        </div>
                                        <div
                                            className='col-lg-4 form-group'
                                            ref={formatTypeRef}>
                                            <label htmlFor=''>
                                              Qualified Call Sample (%)
                                            </label>
                                            <p>
                                                <input
                                                    value={auditValue}
                                                    type='number'
                                                    placeholder='% of call to be audited'
                                                    min={1}
                                                    max={100}
                                                    onInput={e => {
                                                        fetchedNow.current = false;
                                                        if (
                                                            e.target.validity
                                                                .valid
                                                        ) {
                                                            setAuditValue(
                                                                e.target.value
                                                            );
                                                        } else {
                                                            e.target.value = '';
                                                        }
                                                    }}
                                                    style={{ width: '100%', height: "38px", border:"1px solid #d5c9c9", borderRadius: "5px" }}
                                                    onChange={e => {
                                                        setAuditValue(
                                                            e.target.value
                                                        );
                                                    }}
                                                    disabled={
                                                        selectedDepartment?.value
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <Col xs={12} className='text-end mt-3'>
                                            <Button
                                                className='btn comman_btn'
                                                type='button'
                                                onClick={e => {
                                                    handleSubmit(
                                                        e,
                                                        awsCredentialId
                                                            ? 'Update'
                                                            : 'Submit'
                                                    );
                                                }}
                                                disabled={
                                                    selectedDepartment?.value &&
                                                    valueChanged &&
                                                    disablesaveButton
                                                        ? false
                                                        : true
                                                }>
                                                {awsCredentialId
                                                    ? 'Update'
                                                    : 'Submit'}
                                            </Button>
                                        </Col>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AwsForm;
