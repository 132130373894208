import { fetchData } from "../../../services/config";

export const getOrganisationMetricsAgent = async (dateRange, client_id, department_id, sbu_id, level) => {

  const query = {
    measures: [
      'Agent.total_reports',
      'Agent.average_audit_score',
      'Agent.total_departments',
      'Agent.reports_with_fatal',
      'Agent.avg_business_critical',
      'Agent.avg_customer_critical',
      'Agent.avg_compliance_critical'
    ],
    dimensions: ['Agent.organisation_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange, 
      },
    ],
    filters: []
  };

  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  if (department_id && department_id!='0') {
    query.filters.push({
      dimension: 'Agent.department_id',
      operator: 'equals',
      values: [department_id],
    });
  }
  if (level && level!='ALL') {
    query.filters.push({
      dimension: 'Agent.audit_type',
      operator: 'equals',
      values: [level],
    });
  }
  return fetchData(query);

};

export const getDepartmentWiseAuditScore = async (dateRange, client_id, department_id, sbu_id, level) => {
  const query = {
    measures: ['Agent.average_audit_score','Agent.total_reports',],
    dimensions: ['Agent.department_name', 'Agent.department_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange,
      },
    ],
    filters: []
  };
  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (client_id && client_id!='0')  {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  if (department_id && department_id!='0')  {
    query.filters.push({
      dimension: 'Agent.department_id',
      operator: 'equals',
      values: [department_id],
    });
  }
  if (level && level!='ALL') {
    query.filters.push({
      dimension: 'Agent.audit_type',
      operator: 'equals',
      values: [level],
    });
  }
  return fetchData(query);
};

export const getDepartmentsByClient = async (dateRange, client_id, sbu_id, level) => {
    const query = {
      measures: ['Agent.total_departments',],
      dimensions: ['Agent.client_name', 'Agent.client_id'],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: []
    };
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (level && level!='ALL') {
      query.filters.push({
        dimension: 'Agent.audit_type',
        operator: 'equals',
        values: [level],
      });
    }
    return fetchData(query);
  };
  

export const getReportsPerDepartment = async (dateRange, client_id, department_id, sbu_id, level) => {
  const query = {
    measures: ['Agent.total_reports'],
    dimensions: ['Agent.department_id', 'Agent.department_name'],
    timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
    filters: []
  };
  if (sbu_id && sbu_id!='0') {
    query.filters.push({
      dimension: 'Agent.sbu_id',
      operator: 'equals',
      values: [sbu_id],
    });
  }
  if (client_id && client_id!='0')  {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }
  if (department_id && department_id!='0')  {
    query.filters.push({
      dimension: 'Agent.department_id',
      operator: 'equals',
      values: [department_id],
    });
  }
  if (level && level!='ALL') {
    query.filters.push({
      dimension: 'Agent.audit_type',
      operator: 'equals',
      values: [level],
    });
  }
  return fetchData(query);
};

export const getWOWTrendPerDepartment = async (dateRange, client_id, department_id, dateParameter, sbu_id, level) => {
    const query = {
      measures: ['Agent.wow_trend_of_audit_score'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getWOWTrendPerDepartmentAuditCount = async (dateRange, client_id, department_id, dateParameter, sbu_id, level) => {
    const query = {
      measures: ['Agent.total_reports'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

//this
export const getWOWTrendPerDepartmentFatalCount = async (dateRange, client_id, department_id, dateParameter,sbu_id, level) => {
    const query = {
      measures: ['Agent.total_fatal_count'],
      timeDimensions: [
        {
          dimension: `Agent.${dateParameter}`,
          dateRange: dateRange,
        },
      ],
      dimensions: [`Agent.${dateParameter}`, 'Agent.department_name'],
      order: {
        [`Agent.${dateParameter}`]: 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getAgentWiseAverageAuditScore = async (dateRange, client_id, department_id, sbu_id, level) => {
    const query = {
      measures: [
        'Agent.total_reports',
        'Agent.average_audit_score'
      ],
      dimensions: [ 'Agent.agent_name','Agent.agent_id','Agent.audit_type'],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'Agent.agent_name',
        operator: 'notEquals',
        values: [''],
      }],
      order: {
        'Agent.agent_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
        query.filters.push({
          dimension: 'Agent.client_id',
          operator: 'equals',
          values: [client_id],
        });
      }
      if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

//this
export const getQAWiseAverageAuditScore = async (dateRange, client_id, department_id, sbu_id, level) => {
    const query = {
      measures: [
        'QA.total_reports',
        'QA.average_audit_score'
      ],
      dimensions: [ 'QA.qa_name', 'QA.qa_id',],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'QA.qa_name',
        operator: 'notEquals',
        values: [''],
      }],
      order: {
        'QA.qa_name': 'asc',
      },
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'QA.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
        query.filters.push({
          dimension: 'QA.client_id',
          operator: 'equals',
          values: [client_id],
        });
      }
      if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'QA.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getOverallSentimentPerSBU = async (dateRange, client_id, department_id, sbu_id, level) => {
    const query = {
      measures: ['Agent.reports_with_positive_sentiment', 'Agent.reports_with_negative_sentiment', 'Agent.reports_with_neutral_sentiment'],
      timeDimensions: [
        {
          dimension: 'Agent.report_week',
          dateRange: dateRange,
        },
      ],
      dimensions: ['Agent.department_name'],
      order: {
        'Agent.report_week': 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getAgentSentimentPerSBU = async (dateRange, client_id, department_id, sbu_id, level) => {
    const query = {
      measures: ['Agent.reports_with_positive_agent_sentiment', 'Agent.reports_with_negative_agent_sentiment', 'Agent.reports_with_neutral_agent_sentiment'],
      timeDimensions: [
        {
          dimension: 'Agent.report_week',
          dateRange: dateRange,
        },
      ],
      dimensions: ['Agent.department_name'],
      order: {
        'Agent.report_week': 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
    }
    if (level && level!='ALL') {
      query.filters.push({
        dimension: 'Agent.audit_type',
        operator: 'equals',
        values: [level],
      });
    }
    return fetchData(query);
}

export const getCustomerSentimentPerSBU = async (dateRange, client_id, department_id, sbu_id, level) => {
    const query = {
      measures: ['Agent.reports_with_positive_customer_sentiment', 'Agent.reports_with_negative_customer_sentiment', 'Agent.reports_with_neutral_customer_sentiment'],
      timeDimensions: [
        {
          dimension: 'Agent.report_week',
          dateRange: dateRange,
        },
      ],
      dimensions: ['Agent.department_name'],
      order: {
        'Agent.report_week': 'asc',
      },
      filters: []
    }
    if (sbu_id && sbu_id!='0') {
      query.filters.push({
        dimension: 'Agent.sbu_id',
        operator: 'equals',
        values: [sbu_id],
      });
    }
    if (client_id && client_id!='0')  {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}