import React, { useState, useEffect, useRef, useMemo } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import * as AuthSelector from '../../store/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getAudioConfig } from '../../store/auth/actions';
import _ from 'lodash';

const ObsStructure = ({ ...props }) => {
    const [obsStructure, setObsStructure] = useState([]);
    const [obsFormatted, setObsFormatted] = useState([]);
    const obsOrg = props?.obsForOrganization || useSelector(AuthSelector.obs);
    const [obs, setSelectedObs] = useState([]);
    const [objExpanded, setObjExpanded] = useState({});

    const {
        setSelectedDepartment,
        selectedDepartment,
        disabled = false,
        obsLoader = false,
        multiselect = false,
        selectPlaceholder = false,
        preselect = false,
        allView = false,
        getLabelName = false,
        showPartiallySelected = true,
        id
    } = props;
    const dispatch = useDispatch();
    // FETCH OBS FROM BACKEND
    useEffect(() => {
        if (obs) {
            const formattedData =
                ObsFilteredResponse(
                    obs,
                    selectedDepartment?.value,
                    selectedDepartment?.formName,
                    objExpanded
                ) || [];
            setObjExpanded(transformObject(formattedData));
            setObsStructure(obs);
            setObsFormatted(formattedData);
        }
    }, [selectedDepartment, obs]);

    function transformObject(obj) {
        const result = {};
        function extract(obj) {
            obj.forEach(item => {
                result[item.value] = {
                    name: item.label,
                    expanded: item.expanded,
                };
                if (item.children) {
                    extract(item.children);
                }
            });
        }
        extract(obj);
        return result;
    }

    const handleNodeToggle = node => {
        const updatedObj = {
            ...objExpanded,
            [node.value]: {
                ...objExpanded[node.value],
                expanded: node.expanded,
            },
        };
        const updatedObsFormatted = [...obsFormatted];
        updateExpanded(updatedObsFormatted, updatedObj);
        setObsFormatted(updatedObsFormatted);
        setObjExpanded(updatedObj);
    };

    function updateExpanded(obsFormatted, obj) {
        function updateExpandedRecursive(node, parentExpanded) {
            if (obj[node.value] !== undefined) {
                node.expanded = obj[node.value].expanded && parentExpanded;
            }
            if (node.children) {
                if (!node.expanded) {
                    node.children.forEach(child => {
                        child.expanded = false;
                        updateExpandedRecursive(child, false);
                    });
                } else {
                    node.children.forEach(child => {
                        child.expanded = true;
                        if (obj[child.value] !== undefined) {
                            child.expanded = obj[child.value].expanded;
                        }
                        updateExpandedRecursive(child, true && node.expanded);
                    });
                }
            }
        }
        obsFormatted.forEach(item => updateExpandedRecursive(item, true));
        return obsFormatted;
    }

    useEffect(() => {
        if (multiselect && allView) {
            setSelectedObs([
                {
                    Department_Name: 'All',
                    Display_Name: 'All',
                    id: -1,
                    Audio_Config: null,
                    Departments: obsOrg,
                },
            ]);
        } else {
            setSelectedObs(obsOrg);
        }
    }, [obsOrg]);

    useEffect(() => {
        if (allView) {
            const data = document.querySelectorAll('.tag');
            if (data.length) {
                for (let i = 0; i < data.length; i++) {
                    const toolTipText = data[i].getAttribute('aria-label');
                    let text = data[i]?.firstChild?.textContent;
                    const elementsToRemove =
                        data[i]?.getElementsByClassName('tooltipobs');
                    while (elementsToRemove.length > 0) {
                        elementsToRemove[0]?.parentNode?.removeChild(
                            elementsToRemove[0]
                        );
                    }
                    const div = document.createElement('div');
                    div.setAttribute('class', 'tooltipobs');
                    div.innerText = toolTipText;
                    data[i].insertBefore(div, data[i]?.lastChild);
                    text = text?.split('/');
                    const lastText = text.pop();
                    data[i].firstChild.textContent =
                        lastText == 'All' ? lastText : `.../${lastText}`;
                }
            }
        }
        if (getLabelName) {
            const label = findCheckedValues(obsFormatted[0], []);
            getLabelName(label);
        }
    }, [obsFormatted]);

    function findCheckedValues(obj, checkedValues = []) {
        if (obj?.checked === true && obj.label !== 'All') {
            checkedValues.push({ label: obj?.label, tagLabel: obj?.tagLabel });
        }
        if (Array.isArray(obj?.children)) {
            obj?.children.forEach(child => {
                findCheckedValues(child, checkedValues);
            });
        }
        return checkedValues;
    }

    const ObsFilteredResponse = (obs, id, formName, obj) => {
        const op = formatAsNode(obs, id, formName, obj);
        return op?.childDepts;
    };

    const formatAsNode = (nodes, value, formName, obj, start = true) => {
        const depts = [];
        let valueFound = false;
        for (const dept of nodes) {
            if (dept?.Departments) {
                const { childDepts, anyValueFound } = formatAsNode(
                    dept?.Departments,
                    value,
                    formName,
                    obj,
                    false
                );
                if (
                    (value + '').split(',').indexOf(dept.id + '') ||
                    anyValueFound
                ) {
                    valueFound = true;
                }
                const op = {
                    label: dept.Department_Name,
                    value: dept.id,
                    checked: (allView && start) ? childDepts?.every(el => el.checked) : (value + '').split(',').includes(dept.id + ''),
                    expanded: _.get(obj, `${dept.id}.expanded`, false),
                    tagLabel:
                        formName ||
                        dept.Display_Name?.replace(' - Autogenerated', ''),
                    className: `${dept?.Departments?.length > 0 ? (multiselect ? 'disabled' : 'disabled checked focused') : ''}`,
                    children: childDepts,
                    key: dept?.id,
                    Department_Code: dept?.Department_Code
                };
                depts.push(op);
            }
        }
        return {
            anyValueFound: valueFound,
            childDepts: depts.length ? depts : undefined,
        };
    };

    const getSelectedValue = (nodeData, parentNode) => {
        let parent = null;
        let child = null;
        for (const node of nodeData) {
            if (node.checked) {
                parent = parentNode;
                child = node;
            }
            if (node.children) {
                const temp = getSelectedValue(node.children, node);
                if (temp.parent) {
                    parent = temp.parent;
                }
                if (temp.child) {
                    child = temp.child;
                }
            }
        }
        return { parent, child };
    };

    const [isOpen, setIsOpen] = useState('default');

    const onChange = (currentNode, selectedNodes) => {
        if (currentNode._children?.length > 0 && !multiselect) {
            setIsOpen('always');
            setSelectedDepartment({
                formName: '',
                value: selectedDepartment?.value,
                label: '',
                Department_Code: selectedDepartment?.value
            });
            return;
        }
        if (isOpen == 'always') setIsOpen('default');
        let selectedValue;
        if (multiselect) {
            const selectedValues =
                selectedNodes.length > 0
                    ? selectedNodes.map(node => node.value)
                    : null;
            if (selectedValues) {
                for (const vl of selectedValues) {
                    selectedValue =
                        (selectedValue || '') +
                        ',' +
                        (getAllChildren(vl, obs) || '');
                    selectedValue = selectedValue
                        ?.split(',')
                        ?.filter(id => id != '')
                        .join(',');
                }
                selectedValue = selectedValue
                    .replace(/^[,]+/gi, '')
                    .replace(/[,]+$/gi, '')
                    .replace(/,,/gi, '')
                    .replace(/,,/gi, '');
            }
        } else {
            selectedValue =
                selectedNodes.length > 0 ? selectedNodes[0].value : null;
        }

        const formName = '';
        const formattedData = ObsFilteredResponse(
            obsStructure,
            selectedValue,
            formName,
            objExpanded
        );

        setObsFormatted(formattedData);

        const { parent } = getSelectedValue(formattedData, {
            value: '-1',
            lable: 'Root',
        });
        if (selectedNodes[0]?.value) {
            dispatch(getAudioConfig(selectedNodes[0].value));
        }

        setSelectedDepartment({
            label: selectedNodes.length > 0 ? selectedNodes[0].label : '',
            value:
                selectedNodes.length > 0
                    ? multiselect
                        ? selectedValue
                        : selectedNodes[0].value
                    : '',
            formName,
            Department_Code: selectedNodes.length > 0 ? selectedNodes[0].Department_Code : ""
        });
    };
    const getAllChildren = (id, obs) => {
        let str = '';
        for (const deparment of obs) {
            if (deparment.id === id || id === undefined) {
                str += deparment.id + ',';
            }
            if (deparment.Departments) {
                str += getAllChildren(
                    deparment.id === id ? undefined : id,
                    deparment.Departments
                );
            }
        }
        return str;
    };

    return (
        <div
            className={
                allView
                    ? `allView ${obsLoader ? 'obs_loader' : ''}`
                    : `${obsLoader ? 'obs_loader' : ''}`
            }>
            <DropdownTreeSelect
                className='drpdown_tree_select'
                mode={multiselect ? 'multiSelect' : 'radioSelect'}
                texts={{ placeholder: selectPlaceholder ? 'Select' : 'All' }}
                data={obsFormatted}
                onChange={onChange}
                disabled={disabled}
                clearSearchOnChange={true}
                onNodeToggle={handleNodeToggle}
                showPartiallySelected={showPartiallySelected && allView ? true : false}
                showDropdown={isOpen}
                id = {id}
            />
        </div>
    );
};

export default ObsStructure;
