import React, { useEffect, useState } from 'react';
import Card from '../../../bootstrap/card';
import ApexChart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import WordCloud from 'react-wordcloud';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../../../../CommonLogic';
import HorizontalBarWithoutAxis from '../../Widgets/HorizontalBarWithoutAxis';
import LineChartComponent from '../../Widgets/MultiLineGraph';
import TableWithBar from '../../Widgets/TableWithBar';
import SentimentChart from '../../Widgets/SentimentChart';
import DynamicTable from '../../Widgets/Table/TableWithSorting';
import HorizontalBarChartWithPer from '../../Widgets/HorizontalBarChartWithPer';
import ListView from '../../Widgets/ListView';
import Filter from '../../Filter';
// import { fetchAgentWise, fetchDepartmentWise, fetchNumberOfDepartmentsPerClient, fetchOrganisationMetrics, fetchQAWise, fetchSentimentData, fetchWowTrend, fetchWowTrendAuditCount, fetchWowTrendFatalCount } from './service/PageData/Page';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { numberOfProgramsByClient } from './service/PageData/utils';
import { useNavigate } from 'react-router-dom';
import { fetchOverallSentimentData, fetchAgentSentimentData, fetchCustomerSentimentData, fetchDateWise, fetchPerformanceMetrics, fetchPositiveWordCloud, fetchNegativeWordCloud, fetchPositiveReasons, fetchNegativeReasons, fetchPositiveFeedback, fetchNegativeFeedback } from './VoiceOfCustomer/PageData/Page';
import IntentsReport from '../../Widgets/IntentsReport';
import TopicsReport from '../../Widgets/TopicsReport';

const ClientDashboard = () => {
    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [total_departments, setTotalDepartments] = useState(0)
    const [total_fatals, setTotalFatals] = useState(0)
    const [businessCriticalScore, setBusinessCriticalScore] = useState(0)
    const [complianceCriticalScore, setComplianceCriticalScore] = useState(0)
    const [customerCriticalScore, setCustomerCriticalScore] = useState(0)

    const [wowTrendAuditScore, setWowTrendAuditScore] = useState([])
    const [wowTrendFatalCount, setWowTrendFatalCount] = useState([])
    const [wowTrendAuditCount, setWowTrendAuditCount] = useState([])
    const [departmentWise, setDepartmentWise] = useState([])
    const [agentWise, setAgentWise] = useState([])
    const [qaWise, setQaWise] = useState([])
    const [programsPerClient, setProgramsPerClient] = useState(numberOfProgramsByClient())
    const [positiveWordCloud, setPositiveWordCloud] = useState([])
    const [negativeWordCloud, setNegativeWordCloud] = useState([])
    const [positiveReasons, setPositiveReasons] = useState([])
    const [negativeReasons, setNegativeReasons] = useState([])
    const [positiveFeedback, setPositiveFeedback] = useState([])
    const [negativeFeedback, setNegativeFeedback] = useState([])
    const [performanceMetrics, setPerformanceMetrics] = useState([])
    const [overallSentiment, setOverallSentiment] = useState([])
    const [agentSentiment, setAgentSentiment] = useState([])
    const [customerSentiment, setCustomerSentiment] = useState([])
    const [datewiseSentiment, setDatewiseSentiment] = useState([])
    const { analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange, selectedFilters, baseURL } = analyticsData
    const navigate = useNavigate();
    const department_redirection = (id, name) => {
        setFilterData('department', {id: id, name: name})
        navigate(`${baseURL}/program-dashboard/${id}`);
    }
    useEffect(() => {
        fetchOverallSentimentData(setOverallSentiment, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchAgentSentimentData(setAgentSentiment, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchCustomerSentimentData(setCustomerSentiment, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchDateWise(setDatewiseSentiment, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchPerformanceMetrics(setPerformanceMetrics, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchPositiveWordCloud(setPositiveWordCloud, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchNegativeWordCloud(setNegativeWordCloud, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchPositiveReasons(setPositiveReasons, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchNegativeReasons(setNegativeReasons, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchPositiveFeedback(setPositiveFeedback, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
        fetchNegativeFeedback(setNegativeFeedback, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id )
    }, [dateTimeRange, selectedFilters])


    // positive sentimate word cloud
    const PositiveSentimentWords = [
        { text: 'Efficient', value: 100 },
        { text: 'Helpful', value: 95 },
        { text: 'Accurate', size: 90 },
        { text: 'Simple', value: 88 },
        { text: 'Quick', value: 85 },
        { text: 'Reliable', value: 83 },
        { text: 'Professional', value: 80 },
        { text: 'Smooth', value: 78 },
        { text: 'Effective', value: 75 },
        { text: 'Friendly', value: 73 },
        { text: 'Convenient', value: 70 },
        { text: 'Clear', value: 68 },
        { text: 'Fast', value: 65 },
        { text: 'Great Service', value: 63 },
        { text: 'Quality', value: 60 },
    ];
    const PositiveSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#072B15',
            '#0B4522',
            '#126E36',
            '#1DB056',
            '#23CE6B',
            '#27E376',
            '#2FFF8E',
            '#84FFBC',
            '#4080d4',
            '#d978b4',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    // negative sentimate word cloud
    const NegativeSentimentWords = [
        { text: 'Confusing', value: 100 },
        { text: 'Delay', value: 95 },
        { text: 'Difficult', value: 90 },
        { text: 'Long Wait', value: 88 },
        { text: 'Unclear', value: 85 },
        { text: 'Issue', value: 83 },
        { text: 'Problem', value: 80 },
        { text: 'Complicated', value: 78 },
        { text: 'Poor Support', value: 75 },
        { text: 'Error', value: 73 },
        { text: 'Troubleshoot', value: 70 },
        { text: 'Frustrating', value: 68 },
        { text: 'Incorrect', value: 65 },
        { text: 'Cancelled', value: 60 },
        { text: 'Expensive', value: 55 },
    ];
    const NegativeSentimentOptions = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontSizes: [12, 50],
        spiral: 'archimedean', // Circular layout shape
        padding: 2, // Adjust padding between words for readability
        colors: [
            '#7A271A',
            '#912018',
            '#B42318',
            '#D92D20',
            '#F04438',
            '#F97066',
            '#FDA29B',
            '#FECDCA',
            '#f576c5',
            '#fb8b6d',
        ], // Add your custom colors here
        enableTooltip: false, // Disable tooltip
    };

    //
    const PositiveSentimentBar = {
        labels: ['Pkg', 'Clean', 'Staff', 'Avail', 'Response'],
        values: [90, 770, 650, 470, 340],
        colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B'],
    };

    const NegativeSentimentBar = {
        labels: ['Service', 'Support', 'Speed', 'Quality', 'Price'],
        values: [450, 300, 500, 600, 700],
        colors: ['#7A271A', '#912018', '#B42318', '#D92D20', '#F04438'],
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Overall Sentiment
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={overallSentiment?.departments || []}
                                                    yLabel={
                                                        'Overall Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: overallSentiment?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: overallSentiment?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: overallSentiment?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Customer Sentiment
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={customerSentiment?.departments || []}
                                                    yLabel={
                                                        'Customer Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: customerSentiment?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: customerSentiment?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: customerSentiment?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Agent Sentiment</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            <SentimentChart
                                                    xLabel={agentSentiment?.departments || []}
                                                    yLabel={
                                                        'Agent Sentiment'
                                                    }
                                                    data={[
                                                        {
                                                            name: 'Positive',
                                                            data: agentSentiment?.positive || [],
                                                        },
                                                        {
                                                            name: 'Neutral',
                                                            data: agentSentiment?.neutral || [],
                                                        },
                                                        {
                                                            name: 'Negative',
                                                            data: agentSentiment?.negative || [],
                                                        },
                                                    ]}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Date wise Sentiment
                                                            Analysis
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <SentimentChart
                                                    xLabel={datewiseSentiment?.xLabel || []}
                                                    yLabel={'Count'}
                                                    data={datewiseSentiment?.data || []}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    {/* <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Performance Metrics
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <DynamicTable
                                                        headers={[
                                                            'Metrics',
                                                            'Self',
                                                            'Team',
                                                            'Process',
                                                        ]}
                                                        widths={[
                                                            '60%',
                                                            '15%',
                                                            '15%',
                                                            '10%',
                                                        ]}
                                                        data={[
                                                            {
                                                                Metrics:
                                                                    'Opening',
                                                                Self: '82%',
                                                                Team: '82%',
                                                                Process: '82%',
                                                            },
                                                            {
                                                                Metrics: 'Tone',
                                                                Self: '12%',
                                                                Team: '12%',
                                                                Process: '12%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Rate of Speech',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Enunciation',
                                                                Self: '82%',
                                                                Team: '82%',
                                                                Process: '82%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Vocabulary',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Product Knowledge',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Process Knowledge',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Sales Skills',
                                                                Self: '58%',
                                                                Team: '58%',
                                                                Process: '58%',
                                                            },
                                                            {
                                                                Metrics:
                                                                    'Customer Service Skills',
                                                                Self: '87%',
                                                                Team: '87%',
                                                                Process: '87%',
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Call Drivers</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='horizontal_chart_only'>
                                                <div className='horizontal_chart_align_only'>
                                                    <HorizontalBarChartWithPer
                                                        xLabel={[
                                                            'Home Sleep Apnea Testing (HSAT)',
                                                            'Mobile Cardiac Telemetry (MCT)',
                                                            'Overnight Pulse Oximetry (POX)',
                                                            'Real-time Diagnotics',
                                                            'Remote Patient Monitoring',
                                                            'EEG Insomnia Testin',
                                                            'Billing and Reimbursement Issues',
                                                            'Integration with Telehealth',
                                                            'Accurate Diagnostic Reports',
                                                            'User-Friendly Testing Devices',
                                                        ]}
                                                        data={[
                                                            100, 78, 68, 61, 55,
                                                            50, 48, 38, 30, 25,
                                                        ]}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div> */}
                                    <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Performance Metrics
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='table_style_comman'>
                                                    <DynamicTable
                                                        headers={['Team Members',
                                                            'Opening',
                                                            'Closing',
                                                            'Tone',
                                                            'Interruptions',
                                                            'Enunciation',
                                                            'Vocabulary',
                                                            'Conciseness',
                                                            'Context Awareness',
                                                            'Empathy',
                                                            'Sales Skills']}
                                                        widths={[
                                                            '20%'
                                                        ]}
                                                        data={performanceMetrics || []}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>Top 5 Reasons</h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className='sentimate_chart_only-'>
                                                <div className='mb-4'>
                                                    <h6 className='mt-1 graph_sub_heading'>
                                                        Positive Sentiment
                                                    </h6>
                                                    <HorizontalBarWithoutAxis
                                                        categories={
                                                            positiveReasons?.labels
                                                        }
                                                        values={
                                                            positiveReasons?.values
                                                        }
                                                        colors={
                                                            positiveReasons?.colors
                                                        }
                                                    />
                                                </div>
                                                <div className=''>
                                                    <h6 className='graph_sub_heading'>
                                                        Negative Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <HorizontalBarWithoutAxis
                                                            categories={
                                                                negativeReasons?.labels
                                                            }
                                                            values={
                                                                negativeReasons?.values
                                                            }
                                                            colors={
                                                                negativeReasons?.colors
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className='col-lg-6 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Most Common Words
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body className=''>
                                                <div className='mb-4'>
                                                    <h6 className='graph_sub_heading'>
                                                        Positive Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <WordCloud
                                                            words={
                                                                positiveWordCloud
                                                            }
                                                            options={
                                                                PositiveSentimentOptions
                                                            }
                                                            height='300'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <h6 className='graph_sub_heading'>
                                                        Negative Sentiment
                                                    </h6>
                                                    <div className='mt-2'>
                                                        <WordCloud
                                                            words={
                                                                negativeWordCloud
                                                            }
                                                            options={
                                                                NegativeSentimentOptions
                                                            }
                                                            height='400'
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    {/* <div className='col-lg-12 dash_graph dash_graph_height'>
                                        <Card>
                                            <Card.Header className='d-flex justify-content-between card_header'>
                                                <Card.Header.Title className='w-100'>
                                                    <div className='two_column_title'>
                                                        <h5>
                                                            Top 5 Most Common
                                                            Feedback
                                                        </h5>
                                                    </div>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6 className='graph_sub_heading'>
                                                            Positive Sentiment
                                                        </h6>
                                                        <div className='mt-3 '>
                                                            <ListView
                                                                data={positiveFeedback}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6 className='graph_sub_heading'>
                                                            Negative Sentiment
                                                        </h6>
                                                        <div className='mt-3'>
                                                            <ListView
                                                                data={negativeFeedback}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div> */}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientDashboard;
