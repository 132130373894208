import { departmentId } from "../../../../../store/audit-sheet/selectors";
import { fetchData } from "../../../services/config";

export const getOrganisationMetricsAgent = async (dateRange, department_id, client_id, level) => {

  const query = {
    measures: [
      'Agent.total_reports',
      'Agent.average_audit_score',
      'Agent.total_agents',
      'Agent.reports_with_fatal',
      'Agent.avg_business_critical',
      'Agent.avg_customer_critical',
      'Agent.avg_compliance_critical'
    ],
    dimensions: ['Agent.organisation_id'],
    timeDimensions: [
      {
        dimension: 'Agent.report_date',
        dateRange: dateRange, 
      },
    ],
    filters: []
  };

  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'Agent.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }

  if (department_id && department_id!='0') {
    query.filters.push({
      dimension: 'Agent.department_id',
      operator: 'equals',
      values: [department_id],
    });
  }
  if (level && level!='ALL') {
    query.filters.push({
      dimension: 'Agent.audit_type',
      operator: 'equals',
      values: [level],
    });
  }
  return fetchData(query);

};

export const getOrganisationMetricsQA = async (dateRange, department_id, client_id, level) => {
    const query = {
      measures: [
        'QA.total_qas'
      ],
      dimensions: ['QA.organisation_id'],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange, 
        },
      ],
      filters: []
    };

    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'QA.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }

    if (department_id && department_id!='0') {
      query.filters.push({
        dimension: 'QA.department_id',
        operator: 'equals',
        values: [department_id],
      });
    }
    if (level && level!='ALL') {
      query.filters.push({
        dimension: 'QA.audit_type',
        operator: 'equals',
        values: [level],
      });
    }
    return fetchData(query);
};

export const getDateWiseTotalFatalCount = async (dateRange, department_id, client_id, level) => {
    const query = {
      measures: ['Agent.total_fatal_count'],
      timeDimensions: [
        {
          dimension: 'Agent.report_month',
          dateRange: dateRange,
        },
      ],
      dimensions: ['Agent.report_month', 'Agent.department_name'],
      order: {
        'Agent.report_month': 'asc',
      },
      filters: []
    }

    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }
    
    if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getAgentWiseAverageAuditScore = async (dateRange, department_id, client_id, level) => {
    const query = {
      measures: [
        'Agent.total_reports',
        'Agent.average_audit_score'
      ],
      dimensions: [ 'Agent.agent_name','Agent.agent_id',],
      timeDimensions: [
        {
          dimension: 'Agent.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'Agent.agent_name',
        operator: 'notEquals',
        values: [''],
      }],
      order: {
        'Agent.agent_name': 'asc',
      },
    }

    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'Agent.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }

      if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'Agent.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'Agent.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}


export const getQAWiseAverageAuditScore = async (dateRange, department_id, client_id, level) => {
    const query = {
      measures: [
        'QA.total_reports',
        'QA.average_audit_score'
      ],
      dimensions: [ 'QA.qa_name', 'QA.qa_id',],
      timeDimensions: [
        {
          dimension: 'QA.report_date',
          dateRange: dateRange,
        },
      ],
      filters: [{
        dimension: 'QA.qa_name',
        operator: 'notEquals',
        values: [''],
      }],
      order: {
        'QA.qa_name': 'asc',
      },
    }
    if (client_id && client_id!='0') {
      query.filters.push({
        dimension: 'QA.client_id',
        operator: 'equals',
        values: [client_id],
      });
    }

      if (department_id && department_id!='0')  {
        query.filters.push({
          dimension: 'QA.department_id',
          operator: 'equals',
          values: [department_id],
        });
      }
      if (level && level!='ALL') {
        query.filters.push({
          dimension: 'QA.audit_type',
          operator: 'equals',
          values: [level],
        });
      }
    return fetchData(query);
}

export const getDepartmentWiseSubparametersInfo = async (dateRange, department_id, client_id, level) => {
  const query = {
    measures: [
      'AgentSubparameterMv.totalReports'
    ],
    dimensions: [ 'AgentSubparameterMv.subparameterName', 'AgentSubparameterMv.weightage'],
    timeDimensions: [
      {
        dimension: 'AgentSubparameterMv.report_date',
        dateRange: dateRange,
      },
    ],
    filters: []
  }
  if (client_id && client_id!='0') {
    query.filters.push({
      dimension: 'AgentSubparameterMv.client_id',
      operator: 'equals',
      values: [client_id],
    });
  }

    if (department_id && department_id!='0')  {
      query.filters.push({
        dimension: 'AgentSubparameterMv.department_id',
        operator: 'equals',
        values: [department_id],
      });
    }
  return fetchData(query);
}
