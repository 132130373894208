import React, { useState, useEffect, lazy } from 'react';
import Card from '../../../bootstrap/card';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePickerComp from '../../../../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../../../../CommonLogic';
import DataTable from '../../../DataTable';
import SingleLineChart from '../../Widgets/LineChart';
import DynamicTable from '../../Widgets/Table/TableWithSorting';
import { useNavigate } from 'react-router-dom';
import { useAnalyticsContext } from '../../AnalyticsContext';
import Filter from '../../Filter';
import { fetchOrganisationMetrics, fetchWowTrend, fetchWowTrendAuditCount, fetchWowTrendFatalCount } from './services/PageData/Page';
import LineChartComponent from '../../Widgets/MultiLineGraph';

const AgentsDashboard = () => {

    const { analyticsData, handleBackClick, setFilterData } = useAnalyticsContext()
    const { dateTimeRange, selectedFilters, dateParameter } = analyticsData
    const [total_audits, setTotalAudits] = useState(0)
    const [averageAuditScore, setAverageAuditScore] = useState(0)
    const [total_fatals, setTotalFatals] = useState(0)
    const [wowTrendAuditScore, setWowTrendAuditScore] = useState([])
    const [wowTrendFatalCount, setWowTrendFatalCount] = useState([])
    const [wowTrendAuditCount, setWowTrendAuditCount] = useState([])
    const navigate = useNavigate()
    const ticketWiseRedirection = (id) => {
        navigate(`/o/F653-BD0-A380/analytics-dashboard/admin/ticket-wise-dashboard/12`);
    }

    useEffect(() => {
        fetchOrganisationMetrics(setAverageAuditScore, setTotalAudits, setTotalFatals, dateTimeRange, selectedFilters['department']?.id , selectedFilters['agent']?.id)
        fetchWowTrend(setWowTrendAuditScore, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id, selectedFilters['agent']?.id)
        fetchWowTrendAuditCount(setWowTrendAuditCount, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id, selectedFilters['agent']?.id)
        fetchWowTrendFatalCount(setWowTrendFatalCount, dateTimeRange, selectedFilters['client']?.id, selectedFilters['department']?.id, dateParameter, selectedFilters['sbu']?.id, selectedFilters['agent']?.id)
    },[dateTimeRange, selectedFilters])

    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                            <div className='page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'><button className='btn btn_transparent'>
                                            <img src='/images/back_arrow.svg' alt='back button' onClick={handleBackClick}/>
                                        </button></span>
                                        Agent Dashboard
                                    </h3>
                                </div>
                                <Filter showDate showAgent showClient showDepartment/>
                                {/* count */}
                                <div className='row' onClick={ticketWiseRedirection}>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{`${parseFloat(averageAuditScore).toFixed(2)}%`}</h4>
                                            <h6 className='mb-0'>
                                                Avg Audit Score
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{total_audits}</h4>
                                            <h6 className='mb-0'>
                                                Audit Count
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100 text-center'>
                                            <h4 className='mb-2'>{total_fatals}</h4>
                                            <h6 className='mb-0'>
                                                Fatal Count
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* graph */}
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                Audit Score
                                                                Trends
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={wowTrendAuditScore?.data}
                                                        categories={wowTrendAuditScore?.categories}
                                                        yLabel={'Average Audit Score'}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                No. of Audits
                                                                Count
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={wowTrendAuditCount?.data}
                                                        categories={wowTrendAuditCount?.categories}
                                                        yLabel={"Audit Count"}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='dash_graph'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-between card_header'>
                                                    <Card.Header.Title className='w-100'>
                                                        <div className='two_column_title'>
                                                            <h5>
                                                                Fatal Param
                                                                Trend
                                                            </h5>
                                                        </div>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <LineChartComponent
                                                        data={wowTrendFatalCount?.data}
                                                        categories={wowTrendFatalCount?.categories}
                                                        yLabel={"Fatal Count"}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentsDashboard;
