import { getAgentWiseAverageAuditScore, getDateWiseTotalFatalCount, getDepartmentWiseSubparametersInfo, getOrganisationMetricsAgent, getOrganisationMetricsQA, getQAWiseAverageAuditScore } from "../ApiService";
import { formatData, transformDataForChart } from "./utils";

export const fetchOrganisationMetrics = async (setAverageAuditScore, setTotalAudits, setTotalAgents, setTotalFatals, setBusinessCriticalScore, setComplianceCriticalScore, setCustomerCriticalScore, dateTimeRange, department_id, client_id, level) => {
    try {
      let agentresult = await getOrganisationMetricsAgent(dateTimeRange, department_id, client_id, level);
      agentresult = agentresult[0]
      if (!agentresult || agentresult?.length < 1) {
        setAverageAuditScore(0);
        setTotalAudits(0);
        setBusinessCriticalScore(0);
        setComplianceCriticalScore(0);
        setCustomerCriticalScore(0);
        setTotalAgents(0)
        setTotalFatals(0)
        return;
      }

      setTotalAudits(agentresult?.['Agent.total_reports'] || []);
      setAverageAuditScore(agentresult['Agent.average_audit_score']);
      setBusinessCriticalScore(agentresult['Agent.avg_business_critical'] || 0);
      setComplianceCriticalScore(agentresult['Agent.avg_compliance_critical'] || 0);
      setCustomerCriticalScore(agentresult['Agent.avg_customer_critical'] || 0);
      setTotalAgents(agentresult['Agent.total_agents'] || 0)
      setTotalFatals(agentresult['Agent.reports_with_fatal'] || 0)
    } catch (err) {
      console.error('Error fetching data:', err);
    }
};

export const fetchQAMetrics = async (setTotalQa, dateTimeRange, selectedDepartment, client_id, level) => {
    try {
      let qaResult = await getOrganisationMetricsQA(dateTimeRange, selectedDepartment, client_id, level);
      if (qaResult?.length > 0) {
        setTotalQa(qaResult[0]['QA.total_qas']);
      } else {
        setTotalQa(0);
      }
    } catch (err) {
      console.error('Error fetching QA metrics:', err);
    }
  };

  export const fetchFatalTrend = async (setFatalTrend, dateTimeRange, selectedDepartment, client_id, level) => {
    try {
        const result = await getDateWiseTotalFatalCount(dateTimeRange, selectedDepartment, client_id, level);
        const formattedData = formatData(result);
        const fatalTrendData = transformDataForChart(formattedData);
        setFatalTrend(fatalTrendData);
    } catch (err) {
        console.error('Error fetching Wow Trend:', err);
    }
  };

  export const fetchAgentWise = async (setAgentWise, dateTimeRange, department_id, client_id, level) => {
    try {
        let result = await getAgentWiseAverageAuditScore(dateTimeRange, department_id, client_id, level)
        result = result.map((item) => {
            return {
                name: item['Agent.agent_name'],
                score: item['Agent.average_audit_score'],
                count: item['Agent.total_reports']
            }
        })
        setAgentWise(result)
    } catch (err) {
        console.error('Error fetching data:', err);
      }
}

  export const fetchQAWise = async (setQaWise, dateTimeRange, department_id, client_id, level) => {
    try {
        let result = await getQAWiseAverageAuditScore(dateTimeRange, department_id, client_id, level)
        result = result.map((item) => {
            return {
                name: item['QA.qa_name'],
                score: item['QA.average_audit_score'],
                count: item['QA.total_reports']
            }
        })
        setQaWise(result)
    } catch (err) {
        console.error('Error fetching data:', err);
      }
  }

export const fetchSubparameterInfo = async (setSubparameterInfo, dateTimeRange, selectedDepartment, client_id, level) => {
    try {
        const result = await getDepartmentWiseSubparametersInfo(dateTimeRange, selectedDepartment, client_id, level);
        const formattedData = result.map(item => ({
            "Sub-Parameter Name": item["AgentSubparameterMv.subparameterName"],
            "Total Count": item["AgentSubparameterMv.totalReports"],
            "Weightage": item["AgentSubparameterMv.weightage"]
        }));
        setSubparameterInfo(formattedData);
      } catch (err) {
        console.error('Error fetching country-wise audit metrics:', err);
      }
  };