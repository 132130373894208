
import { getAgentDataperSBU, getCountryWiseAuditScore, getDepartmentsPerSbu, getGeoWiseAuditScore, getOrganisationMetricsAgent, getOrganisationMetricsQA, getQADataperSBU, getSbuWiseAuditScore, getWowTrendPerSBU } from '../ApiService';
import { transformAgentData, transformQAData, formatData, transformDataForChart } from './utils';


export const fetchAgentMetrics = async (settotalAgents, setAverageAuditScore, setTotalAudits, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
    let agentResult = await getOrganisationMetricsAgent(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
    if (agentResult?.length < 1) {
      settotalAgents(0);
      setAverageAuditScore(0);
      setTotalAudits(0);
      return;
    }
    agentResult = agentResult[0];
    settotalAgents(agentResult['Agent.total_agents']);
    setAverageAuditScore(agentResult['Agent.average_audit_score']);
    setTotalAudits(agentResult['Agent.total_reports']);
  } catch (err) {
    console.error('Error fetching agent metrics:', err);
  }
};

export const fetchQAMetrics = async (setTotalQa, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
    let qaResult = await getOrganisationMetricsQA(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
    if (qaResult?.length > 0) {
      setTotalQa(qaResult[0]['QA.total_qas']);
    } else {
      setTotalQa(0);
    }
  } catch (err) {
    console.error('Error fetching QA metrics:', err);
  }
};

export  const fetchCountryWiseAuditMetrics = async (setCountryWiseAuditScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
    const result = await getCountryWiseAuditScore(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
    setCountryWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching country-wise audit metrics:', err);
  }
};

export const fetchSBUWiseAuditMetrics = async (setSbuWiseAuditScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
    const result = await getSbuWiseAuditScore(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
    setSbuWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching SBU-wise audit metrics:', err);
  }
};

export const fetchDepartmentsPerSbu = async (setDepartmentsPerSbu, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
      const result = await getDepartmentsPerSbu(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
      setDepartmentsPerSbu(result);
  } catch (err) {
      console.error('Error fetching Departments per SBU:', err);
  }
};

export const fetchGeoWiseAuditMetrics = async (setGeoWiseAuditScore, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
  try {
    const result = await getGeoWiseAuditScore(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
    setGeoWiseAuditScore(result);
  } catch (err) {
    console.error('Error fetching Geo-wise audit metrics:', err);
  }
};

export const fetchWowTrend = async (setWowTrend, dateTimeRange, organisation_id, selectedSBU, selectedGeo, dateParameter, selectedLevel) => {
  try {
      const result = await getWowTrendPerSBU(dateTimeRange, organisation_id, selectedSBU, selectedGeo, dateParameter, selectedLevel);
      const formattedData = formatData(result, dateParameter);
      const wowTrendData = transformDataForChart(formattedData);
      setWowTrend(wowTrendData);
  } catch (err) {
      console.error('Error fetching Wow Trend:', err);
  }
};

export const fetchAgentsBySBUData = async (setTotalAgentsBySbu, dateTimeRange, organisation_id,  selectedSBU, selectedGeo, selectedLevel) => {
    try {
        const result = await getAgentDataperSBU(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
        setTotalAgentsBySbu(transformAgentData(result));
    } catch (err) {
        console.error('Error fetching Agents by SBU:', err);
    }
};

export const fetchQABySBUData = async (setTotalQasBySbu, dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel) => {
    try {
        const result = await getQADataperSBU(dateTimeRange, organisation_id, selectedSBU, selectedGeo, selectedLevel);
        setTotalQasBySbu(transformQAData(result));
    } catch (err) {
        console.error('Error fetching QA by SBU:', err);
    }
};
