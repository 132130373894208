import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import Card from '../../../../components/bootstrap/card';
import {
    Accordion,
    Breadcrumb,
    Button,
    Form,
    Modal,
    Tooltip,
} from 'react-bootstrap';
import Audio from './Audio';
import { useSelector } from 'react-redux';
import { client } from '../../../../services/apiService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as AuthSelector from '../../../../store/auth/selectors';
import CustomDataTable from '../../../CustomDataTable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import RebuttalComments from './RebuttalComments';
import { kpiBaseRoot, RC_AGENT, RC_EDIT,EXACT_MATCH_SEPARATOR } from '../../../../Constant';
import {
    ErrorNotify,
    delay,
    imagePath,
    infoNotify,
    lodashGet,
    orderObject,
    successNotify,
    toolTipHOC,
} from '../../../../CommonLogic';
import {
    materialRenderers,
    materialCells,
} from '@jsonforms/material-renderers';
import {
    InputFormControl,
    InputFormTester,
} from '../../../../formRender/FormRenderComponent/InputText';
import {
    DateFormControl,
    DateFormTester,
} from '../../../../formRender/FormRenderComponent/Date';
import {
    DropdownFormControl,
    DropdownFormTester,
} from '../../../../formRender/FormRenderComponent/Enum';
import { JsonForms } from '@jsonforms/react';
import { getEncoding } from 'js-tiktoken';
import { criticalArray } from '../../../../components/Audit/AuditSheet';
import { ButtonType, scoreMapping } from './audit';
import axios from 'axios';
const criticalScoreTemplate = { Customer: 0, Business: 0, Compliance: 0 };

const PerformAudit = ({ showFor, mode, auditStatus }) => {
    const [showModal, setShowModal] = useState(false);
    const [buttonDisable, SetButtonDisable] = useState(true);
    const handleShowModal = async e => {
        const validateField = await validateCustomFields(e);
        if (validateField) {
            setShowModal(true);
            SetButtonDisable(false);
        }
    };
    const [comments, setComments] = useState([]);
    const location = useLocation();
    const clonedlocation = useMemo(()=>_.cloneDeep(location),[]);

    const { ticketId } = useParams();

    const audioUrl =
        clonedlocation?.state?.audioUrl || clonedlocation?.state?.auditData?.Media_Url;
    const crm_import_id = clonedlocation?.state?.crm_import_id;
    const agent_id = clonedlocation?.state?.agent_id;
    const transcription = clonedlocation?.state?.transcription;
    const conversationSummary = clonedlocation?.state?.conversation_summary;
    const conversationSentiment = clonedlocation?.state?.conversation_sentiment;
    const agentSentiment = clonedlocation?.state?.agent_sentiment;
    const customerSentiment = clonedlocation?.state?.customer_sentiment;
    const sentimentSummary = clonedlocation?.state?.sentiment_summary;
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organisation = useSelector(AuthSelector.organization);
    const navigate = useNavigate();
    const [auditSheetData, setAuditSheetData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [responses, setResponses] = useState([]);
    const responseRef = useRef({})
    const [possibleScore, setPossibleScore] = useState(0);
    const [earnedScore, setEarnedScore] = useState(0);
    const [percent, setPercent] = useState(0);
    const apiFormatRef = useRef(null);
    const [auditFormId, setAuditFormId] = useState(null);
    const [comment, saveComment] = useState(null);
    const [saveButtonDisable, SetSaveButtonDisable] = useState(true);
    const [
        saveButtonDisableByRemarksAndDropDown,
        SetSaveButtonDisableByRemarksAndDropDown,
    ] = useState(true);
    const [isParameterEditable, setIsParameterEditable] = useState(false);
    const [department, setDepartment] = useState({});
    const verbatimColumnRef = useRef(null);
    const [departmentToolTip, setDepartmentToolTip] = useState({ title: "", toolTipTitle: "" });
    const [conversation, setConversation] = useState([]);
    const [conversationString, setConversationString] = useState('');
    const [departmentBasePrompt, setDepartmentBasePrompt] = useState('');
    const [transcriptionDetailedSummary, setTranscriptionDetailedSummary] = useState('');
    const [summarizerBasePrompt, setSummarizerBasePrompt] = useState('');
    const [summaryLoader, setSummaryLoader] = useState(false);
    const [parameter, setParameter] = useState([]);
    const [intent, setIntent] = useState([]);
    const [topic, setTopic] = useState([]);
    const [aiDrivenAudit, setAiDrivenAudit] = useState(false);
    const [qaReport, setQaReport] = useState([]);
    const EditablebeforeTat = useRef(false)
    const [cloneResponseData, setcloneResponseData] = useState([]);
    const [schema, setSchema] = useState({ type: 'object', properties: {} });
    const [uiSchema, setUischema] = useState({
        type: 'VerticalLayout',
        elements: [],
    });
    const [uiSchemaCopy, setUischemaCopy] = useState({
        type: 'VerticalLayout',
        elements: [],
    });
    const [renderers] = useState([
        ...materialRenderers,
        { tester: InputFormTester, renderer: InputFormControl },
        { tester: DateFormTester, renderer: DateFormControl },
        { tester: DropdownFormTester, renderer: DropdownFormControl },
    ]);
    const payloadData = useRef({});
    const jsonsDataRef = useRef({})
    const aiResponseTime = useRef(null);
    const [subParamTime, setSubParamTime] = useState({});
    const paramReportDataSorted = useMemo(() => {
        return orderObject(
            clonedlocation?.state?.auditData?.Report_Data,
            clonedlocation?.state?.auditData?.Report_Data_Order,
            true
        );
    }, []);

    const paramReportDataSortedcloned = _.cloneDeep(paramReportDataSorted)
    const setParamReportDataClone= useRef(null)


    const [paramReportData, setParamReportData] = useState({});
    const [currAudioTime, setCurrAudioTime] = useState(0);
    const activeRef = useRef(null);
    const containerRef = useRef(null);
    const qaViewAuditIdRef = useRef(null)
    async function qaViewReport(id) {
        const response = await client.get(`/qa-report/${id}`);
        if(response.success){
            EditablebeforeTat.current = !response?.data?.Agent_Action_Taken && isCurrentDateBeforeGivenDate(response?.data?.Expired_On)
            setQaReport(response?.data);
            let reportData = response?.data?.Report_Data;
            let reportDataOrder = response?.data?.Report_Data_Order
            qaViewAuditIdRef.current= response?.data?.id
            getComments(false, response?.data.id);
            setDepartmentToolTip(getDepartmentName(response?.data?.CRM_Import?.Department?.Display_Name));
            setDepartment(response?.data?.CRM_Import?.Department)
            const orderObjectResponse = orderObject(reportData, reportDataOrder, true)
            setParamReportData(orderObjectResponse);
            setParamReportDataClone.current=_.cloneDeep(orderObjectResponse)
            fetchCrmData(id);
            setcloneResponseData(transformDataForObjectMatch(orderObjectResponse));
        }else{
            const errorMessage= response?.error?.response?.data?.data?.error?.message
            errorMessage && ErrorNotify(errorMessage)
        }

        if (
            EditablebeforeTat.current &&
            response?.data?.CRM_Import?.Department?.Ai_Audit
        ) {
            const aiResponse = await client.get(
                `/audit-forms?filters[Department]=${response?.data?.CRM_Import?.Department?.id}&filters[Is_Active]=true&populate[0]=Created_By.username&populate[1]=Department&sort=Version:desc&populate[2]=Audit_Parameters.Audit_Subparameters&populate[3]=Audit_Parameters.Audit_Subparameters.Audit_L_2_Options`
            );

            if (aiResponse?.data) {
                setDepartmentBasePrompt(
                    aiResponse?.data[0]?.attributes?.Department?.data
                        ?.attributes?.Department_Base_Prompt
                );
                setSummarizerBasePrompt(
                    aiResponse?.data[0]?.attributes?.Department?.data
                        ?.attributes?.Summarizer_Base_Prompt
                );
                let temp = JSON.parse(JSON.stringify(aiResponse.data));
                temp = temp.map(auditsheet => {
                    auditsheet.attributes.Audit_Parameters.data = _.orderBy(
                        auditsheet.attributes.Audit_Parameters.data,
                        ['id'],
                        ['asc']
                    );
                    return auditsheet;
                });
                setParameter(temp[0]?.attributes?.Audit_Parameters?.data);
            }
        }
    }

    const [viewMore, setViewMore] = useState(false);
    const submitFunctionSingleCall = useRef(false);
    const saveFunctionSingleCall = useRef(false);
    const aiAuditButton = useRef(null);
    const summaryFunctionCall = useRef(null);
    const [sentiment, setSentiment] = useState('');
    const [customersentiment, setCustomerSentiment] = useState('');
    const [agentsentiment, setAgentSentiment] = useState('');
    const [sentimentSummaryParagraph, setSentimentSummary] = useState('');
    const [metadata, setMetadata] = useState('');
    const [auditLoader, setAuditLoader] = useState(false);
    const [kpi, setKpi] = useState({});
    const [preloadedTranscription, setPreLoadedTranscription] = useState({})

    useEffect(() => {
        if (["agentView", "rebuttalJourney", "qaView"]?.includes(auditStatus)) {
            const schema =
                clonedlocation?.state?.auditData?.Custom_Fields_Data?.Custom_Fields_Schema || qaReport?.Custom_Fields_Data?.Custom_Fields_Schema;
            const uiSchema =
                clonedlocation?.state?.auditData?.Custom_Fields_Data?.Custom_Fields_UI || qaReport?.Custom_Fields_Data?.Custom_Fields_UI
            const data = clonedlocation?.state?.auditData?.Custom_Fields_Data?.data || qaReport?.Custom_Fields_Data?.data;
            if (schema && uiSchema) {
                if (auditStatus == "agentView") {
                    const modifiedObject = Object.fromEntries(
                        Object.entries(schema?.properties).map(
                            ([key, value]) => [
                                key,
                                { ...value, disabled: true },
                            ]
                        )
                    );
                    setSchema({ type: 'object', properties: modifiedObject });
                } else {
                    for (const key in schema?.properties) {
                        if (schema.properties.hasOwnProperty(key)) {
                            schema.properties[key].handleChange = function (e) {
                                handleChangeField(e);
                            };
                            schema.properties[key].showError = false;
                        }
                    }
                    if (auditStatus == 'qaView' && EditablebeforeTat.current) {
                        setSchema(schema);
                        payloadData.current = data;
                    } else {
                        if (!isParameterEditable) {
                            const modifiedObject = Object.fromEntries(
                                Object.entries(schema?.properties).map(
                                    ([key, value]) => [
                                        key,
                                        { ...value, disabled: true },
                                    ]
                                )
                            );
                            setSchema({
                                type: 'object',
                                properties: modifiedObject,
                            });
                        } else {
                            setSchema(schema);
                        }
                    }
                    payloadData.current = data;
                }
                setUischemaCopy(uiSchema);
                const firstNHorizontalLayouts = getFirstNHorizontalLayouts(
                    uiSchema,
                    2
                );
                setUischema({
                    type: 'VerticalLayout',
                    elements: firstNHorizontalLayouts,
                });
                jsonsDataRef.current = {...data}
            }
        }
    }, [isParameterEditable, qaReport]);
            
    useEffect(() => {
        if (auditStatus != 'qaView') {
            setcloneResponseData(transformDataForObjectMatch(paramReportDataSorted));
        }
    }, []);

    useEffect(() => {
        if (auditStatus == 'initialAudit' && !department.Ai_Audit) {
            const allFirstOption =
                document.querySelectorAll('.firstoption') || [];
            allFirstOption.forEach(el => {
                el?.click();
            });
        }
    }, [auditSheetData]);
    
    useEffect(() => {
        if (transcription && summarizerBasePrompt) {
            handleTranscription(transcription);
        }
    }, [transcription, summarizerBasePrompt]);
    // Agent Page View
    useEffect(() => {
        if (showFor == 'Agent' && ticketId) {
            fetchCrmData(ticketId);
        }
    }, [showFor, ticketId]);

    async function fetchCrmData(ticket) {
        try {
            const response = await client.post('/crm-imports/dataByTicket', {
                data: {
                    "TicketId": ticket
                }
            });
            if(response.success)
            {
                if(response?.data?.data?.Transcription)
                {
                     const result = response?.data?.data;
                     setTranscriptionDetailedSummary(result?.Conversation_Summary);
                     setSentiment(result?.Sentiment);
                     setAgentSentiment(result?.Agent_Sentiment);
                     setCustomerSentiment(result?.Customer_Sentiment);
                     setSentimentSummary(result?.Sentiment_Summary);
                     setConversation(result?.Transcription?.transcript);
                     if (result?.Transcription?.topics?.length > 0) {
                        setTopic(result.Transcription.topics)
                    }
                    if (result?.Transcription?.intents?.length > 0) {
                        setIntent(result.Transcription.intents)
                    }
                     setPreLoadedTranscription(result?.Transcription);
                     const payload = {
                        callStartTime: result?.Transcription?.start_time,
                        callEndTime: result?.Transcription?.end_time,
                        transcript: result?.Transcription?.transcript?.map(el => {
                            return {
                                from: el?.speaker === "Agent" ? el?.speaker?.toLowerCase() : "prospect",
                                message: el?.utterance,
                            };
                        }),
                    };
                    if(department?.Omind_API){
                        try {
                        const res = await axios.post(kpiBaseRoot, payload, {headers: {
                            'X-Client': organisation?.organisationName?.includes("Conduent") ? "Conduent" : ""
                        }});
                        const finalKpi = removeKeys(res?.data);
                        setKpi(finalKpi);
                    } catch(error) {
            
                    }}
                }
            }
        } catch (error) {

        }
    }

    const performAuditHome = `/o/${orgStrId}/perform-audit/list`;
    const viewAuditHome = `/o/${orgStrId}/view-my-audit/list`;

    function extractAiRelatedParams(obj, verbatim = false) {
        const subParameterName = obj.Subparameter_Name;
        const Description = obj.Description;
        const weightage = obj.Weightage;
        const isFatal = obj.Is_Fatal;
        const hasRemark = obj.Has_Remark;
        const hasL2Dropdown = obj.Has_L2_Dropdown;
        const Is_Ai_Auditable = obj.Is_Ai_Auditable;
        const Critical_Type = obj.Critical_Type;
        const Response_Type = obj.Response_Type;
        let verbatimQuestion = ''
        let resultString = `Question : ${Description ? Description : subParameterName}\n`;
        if (obj.SOP_Statement || Description) {
            verbatimQuestion += `Script SOP : ${obj.SOP_Statement ? obj.SOP_Statement : Description ? Description : subParameterName}\n`;
        }
        if (obj.Ai_Audit_Exact_Match_Text) {
            const exactMatches = obj.Ai_Audit_Exact_Match_Text.split(EXACT_MATCH_SEPARATOR);
            verbatimQuestion += `Exact Match : ${JSON.stringify(exactMatches)}\n`
        }
        if (hasL2Dropdown && obj.Audit_L_2_Options?.data?.length > 0) {
            const options = [];
            obj.Audit_L_2_Options?.data?.forEach(item => {
                options.push(item?.attributes?.title);
            });
            resultString += `Dropdown Options : ${options.join('\n')}\n`;
            resultString += `-------------\n`
        }
        if(verbatimQuestion)
        {
            verbatimQuestion += `-------------\n`
        }

        return {
            subParameterName: subParameterName,
            SubParameter: verbatim ? verbatimQuestion : resultString,
            Weightage: weightage,
            Is_Fatal: isFatal,
            Has_Remark: hasRemark,
            Has_L2_Dropdown: hasL2Dropdown,
            Is_Ai_Auditable: Is_Ai_Auditable,
            Critical_Type: Critical_Type,
            Response_Type: Response_Type,
        };
    }

    const [criticalScoreCalculated, setCriticalScoreCalculated] = useState({
        ...criticalScoreTemplate,
    });

    const handleResponse = (
        Parameter_Name,
        Weightage,
        Is_Fatal,
        Critical_Type,
        Subparameter_Name,
        rowNum,
        e,
        isReport
    ) => {
        const id = rowNum;
        const weight = Weightage;
        const critical_type = Critical_Type;
        const value = e.target.getAttribute('value');
        const fatal = Is_Fatal;

        let updatedResponses;
        setResponses(prevResponses => {
            updatedResponses = [...prevResponses];
            updatedResponses[id] = {
                type: value,
                weight,
                fatal,
                critical: critical_type,
            };
            return updatedResponses;
        });
        responseRef.current = updatedResponses

        const apiFormatUpdated = updateStatus(
            Parameter_Name,
            Subparameter_Name,
            e.target.value
        );
        if (
            apiFormatUpdated?.data?.Report_Data[Parameter_Name][0][
                Subparameter_Name
            ]
        ) {
            apiFormatUpdated.data.Report_Data[Parameter_Name][0][
                Subparameter_Name
            ].Ai_Assisted_Audit = false;
        }
        apiFormatRef.current = apiFormatUpdated;

        if (isReport) {
            setParamReportData({ ...apiFormatUpdated });
        }

        if (auditStatus == 'rebuttalJourney' || auditStatus=="qaView") {
            const disableSaveButton = !_.isEmpty(jsonsDataRef.current)
                ? _.isEqual(jsonsDataRef.current, payloadData.current) && _.isEqual(responseRef.current, cloneResponseData)
                : _.isEqual(responseRef.current, cloneResponseData);


            if (disableSaveButton) {
                SetSaveButtonDisable(true);
            } else {
                if (saveButtonDisable === true) {
                    SetSaveButtonDisable(false);
                }
            }
        }
    };
    function calculateScores(data) {
        if (!data.length)
            return {
                possibleScoreCal: 0,
                earnedScore: 0,
                percent: 0,
                criticalScores: {},
            };

        let possibleScoreCal = 0;
        let earnedScore = 0;
        let fatalTriggered = false;

        // Initialize critical scores and fatal flags for each critical category
        const criticalScores = {
            Customer: { possible: 0, earned: 0, fatal: false },
            Business: { possible: 0, earned: 0, fatal: false },
            Compliance: { possible: 0, earned: 0, fatal: false },
        };

        for (const key of data) {
            const { type = '', weight = 0, fatal, critical = '' } = key || {};
            if (type !== 'N/A') {
                const multiplicationFactor = scoreMapping[type];
                if (fatal && multiplicationFactor === 0) {
                    fatalTriggered = true;

                    if (critical && critical != 'None' && critical != '' && criticalScores[critical]) {
                        criticalScores[critical].fatal = true;
                    }// Mark critical score as fatal
                }

                possibleScoreCal += weight;
                earnedScore +=
                    weight *
                    (!isNaN(multiplicationFactor) ? multiplicationFactor : 0);

                if (critical && criticalScores[critical]) {
                    criticalScores[critical].possible += weight;
                    criticalScores[critical].earned +=
                        weight *
                        (!isNaN(multiplicationFactor)
                            ? multiplicationFactor
                            : 0);
                }
            }
        }

        if (fatalTriggered) {
            earnedScore = 0;
        }
        Object.keys(criticalScores).forEach(key => {
            if (criticalScores[key].fatal) {
                criticalScores[key].earned = 0;
            }
        });
        const percent = possibleScoreCal
            ? (earnedScore / possibleScoreCal) * 100
            : 0;
        const criticalScorePercentages = Object.keys(criticalScores).reduce(
            (acc, key) => {
                const { possible, earned } = criticalScores[key];
                acc[key] = possible
                    ? ((earned / possible) * 100)
                          .toFixed(1)
                          .replace(/\.00$/, '')
                    : '0.0';
                return acc;
            },
            {}
        );

        return {
            possibleScoreCal,
            earnedScore: earnedScore.toFixed(1).replace(/\.00$/, ''),
            percent: percent.toFixed(1).replace(/\.00$/, ''),
            criticalScores: criticalScorePercentages, // Critical score percentages by category
        };
    }

    useEffect(() => {
        const { possibleScoreCal, earnedScore, percent, criticalScores } =
            calculateScores(responses);
        setPercent(prev =>
            possibleScoreCal === 0 ? '0.0' : percent !== prev ? percent : prev
        );
        if (possibleScore != possibleScoreCal) {
            setPossibleScore(possibleScoreCal);
        }
        setEarnedScore(prev => (earnedScore !== prev ? earnedScore : prev));
        setCriticalScoreCalculated(criticalScores);
    }, [responses]);

    const handleAIAudit = async () => {
        if (aiAuditButton.current == true) {
            return;
        }
        setAuditLoader(true);
        aiAuditButton.current = true;
        const descriptions = [];
        const subparameterResponseTypes = [];
        const subparamMapping = [];
        let count = 0;
        let auditableSubParameter = 0;
        for (let i = 0; i < parameter.length; i++) {
            const parentParam =
                parameter[i]?.attributes?.Audit_Subparameters?.data;
            const subparamLength = parentParam?.length || 0;
            const param = parameter[i]?.attributes?.Parameter_Name;

            for (let j = 0; j < subparamLength; j++) {
                const {
                    subParameterName,
                    SubParameter,
                    Weightage,
                    Is_Fatal,
                    Has_Remark,
                    Has_L2_Dropdown,
                    Is_Ai_Auditable,
                    Critical_Type,
                    Response_Type,
                } = extractAiRelatedParams(parentParam[j]?.attributes, department?.Ai_Audit_Verbatim_Model ? department.Ai_Audit_Verbatim_Model : false);
                if (Is_Ai_Auditable) {
                    auditableSubParameter++;
                    descriptions.push(SubParameter);
                    subparameterResponseTypes.push({
                        Response_Type: Response_Type
                            ? Response_Type
                            : 'Yes, Partial, No, N/A',
                    });
                    subparamMapping.push({
                        param,
                        subParameterName,
                        Weightage,
                        Is_Fatal,
                        Has_Remark,
                        Has_L2_Dropdown,
                        count,
                        Critical_Type,
                        Response_Type,
                    });
                }
                count++;
            }
        }
        if (auditableSubParameter == 0) {
            infoNotify('Audit sheet is not enabled for AI auditing.');
            aiAuditButton.current = false;
            setAuditLoader(false);
            return;
        }
        let response;
        const payload = {
            basePrompt: departmentBasePrompt || '',
            chatHistory:
                conversationString && isWithinTokenLimit(conversationString)
                    ? conversationString
                    : transcriptionDetailedSummary,
            parameters: descriptions,
            responseType: subparameterResponseTypes,
            metadata: metadata,
            department: department.Department_Code
        }
         response = await client.post('/crm-imports/aiaudit', {
            data: payload
        });
        let dataFromModel;
            dataFromModel = response?.data?.data || [];

        if(dataFromModel?.length!==subparamMapping?.length){
            response = await client.post('/crm-imports/aiaudit', {
                data: payload
            });
            dataFromModel = response?.data?.data || [];
        }
        if (response?.data?.data?.message) {
            infoNotify(response.data.data.message);
            setAiDrivenAudit(true);
            setAuditLoader(false);
            return;
        }

        const responsesTempAi = [...responses];
        let arr = [];
        if (dataFromModel && dataFromModel.length > 0) {
            subparamMapping.forEach((el, index) => {
                const aiResult = dataFromModel[index]
                const {
                    param,
                    subParameterName,
                    Weightage,
                    Is_Fatal,
                    Has_Remark,
                    Has_L2_Dropdown,
                    count,
                    Critical_Type,
                } = subparamMapping[index];
                arr[count] = { start: aiResult?.start, end: aiResult?.end };

                responsesTempAi[count] = {
                    type: aiResult.type,
                    weight: Weightage,
                    fatal: Is_Fatal,
                    critical: Critical_Type,
                };

                const apiFormatUpdated = updateStatus(
                    param,
                    subParameterName,
                    aiResult.type
                );

                if (
                    [
                        'Yes',
                        'PARTIAL',
                        'No',
                        'N/A',
                        'Excellent',
                        'Average',
                        'Fair',
                        'Needs Improvement',
                        'Unsatisfactory',
                        '5',
                        '4',
                        '3',
                        '2',
                        '1',
                        'Compliant',
                        'Non-Compliant',
                        'Complete',
                        'Incomplete',
                        'Pass',
                        'Fail',
                        'Observed',
                        'Not Observed',
                        'Met',
                        'Partially Met',
                        'Not Met',
                        'Acceptable',
                        'Unacceptable',
                        'Non-Fatal',
                        'Fatal',
                        'Minor',
                        'Medium',
                        'Severe',
                        'Critical',
                        'No Coaching',
                        'Coaching',
                    ].includes(aiResult.type)
                ) {
                    let element = document.getElementById(`${aiResult.type}-${count}`);
                    if (element) element.click();
                    if (param) {
                        if (apiFormatUpdated?.data?.Report_Data) {
                            apiFormatUpdated.data.Report_Data[param][0][
                                subParameterName
                            ].Ai_Assisted_Audit = true;
                        } else {
                            apiFormatUpdated[param][0][
                                subParameterName
                            ].Ai_Assisted_Audit = true;
                        }
                    }
                }

                if (Has_Remark && aiResult.remarks) {
                    if (param) {
                        if (apiFormatUpdated?.data?.Report_Data) {
                            apiFormatUpdated.data.Report_Data[param][0][
                                subParameterName
                            ].remark = aiResult.remarks;
                        } else {
                            apiFormatUpdated[param][0][subParameterName].remark =
                                aiResult.remarks;
                        }
                    }
                    document.querySelector(`#remark${count}`).value =
                        aiResult.remarks;
                }

                if (Has_L2_Dropdown && aiResult.dropdown) {
                    if (param) {
                        if (apiFormatUpdated?.data?.Report_Data) {
                            apiFormatUpdated.data.Report_Data[param][0][
                                subParameterName
                            ].L2_Dropdown_Value = aiResult.dropdown;
                        } else {
                            apiFormatUpdated[param][0][
                                subParameterName
                            ].L2_Dropdown_Value = aiResult.dropdown;
                        }
                    }
                    document.querySelector(`#reason${count}`).value =
                        aiResult.dropdown;
                }

                if (department.Ai_Audit_Verbatim_Model) {
                    document.querySelector(`#verbatim${count}`).value =
                    aiResult?.scriptAccuracy;
                    if (apiFormatUpdated?.data?.Report_Data) {
                        apiFormatUpdated.data.Report_Data[param][0][
                            subParameterName
                        ].Verbatim = aiResult?.scriptAccuracy;

                        apiFormatUpdated.data.Report_Data[param][0][
                            subParameterName
                        ].start = aiResult?.start;

                        apiFormatUpdated.data.Report_Data[param][0][
                            subParameterName
                        ].end = aiResult?.end;
                    } else {
                        apiFormatUpdated[param][0][subParameterName].Verbatim =
                            aiResult?.scriptAccuracy;
                        apiFormatUpdated[param][0][subParameterName].start =
                            aiResult?.start;
                        apiFormatUpdated[param][0][subParameterName].end =
                            aiResult?.end;
                    }
                }
                try {
                    if (aiResult?.start && aiResult?.end) {
                        const element = document.getElementById(`subparam-${count}`);
                        if (element) {
                            element.className = "pointer_cur link_underline";
                        }
                    }
                } catch (error) {
                }
                apiFormatRef.current = apiFormatUpdated;
            });
        }
        aiResponseTime.current = arr
        setResponses([...responsesTempAi]); // handleAIAudit
        responseRef.current = [...responsesTempAi]
        setAiDrivenAudit(true);
        setAuditLoader(false);
    };

    const updateCommentAndDropdown = (param, subparam, value, status) => {
        const apiFormatUpdated = updateStatus(param, subparam, value, status);
        if (apiFormatUpdated?.data?.Report_Data) {
            apiFormatUpdated.data.Report_Data[param][0][
                subparam
            ].Ai_Assisted_Audit = false;
        } else {
            apiFormatUpdated[param][0][subparam].Ai_Assisted_Audit = false;
            
        }
        apiFormatRef.current = apiFormatUpdated;
        if (mode == 'Edit' || auditStatus=="qaView") {
            const disableSaveButton = _.isEqual(
                auditStatus=="qaView"?setParamReportDataClone.current:paramReportDataSortedcloned,
                apiFormatUpdated
            );


            if (disableSaveButton) {
                SetSaveButtonDisableByRemarksAndDropDown(true);
            } else {
                if (saveButtonDisableByRemarksAndDropDown) {
                    SetSaveButtonDisableByRemarksAndDropDown(false);
                }
            }
        }
    };

    const formatAsApiData = data => {
        const apiFormatSingle = {
            data: {
                Overall_Score: 0,
                Report_Data: {},
                Audit_Form: auditFormId,
                Is_Active: true,
                Audit_Status_Current: 'Audit Done',
                Media_Url: audioUrl,
                Ticket_Id: ticketId,
                CRM_Import: crm_import_id,
                Agent_Info: agent_id,
            },
        };
        for (const param of data.attributes.Audit_Parameters.data) {
            const paramName = param.attributes.Parameter_Name;
            const paramObj = {};
            for (const subparam of param.attributes.Audit_Subparameters.data) {
                paramObj[subparam.attributes.Subparameter_Name] = {
                    Weightage: subparam.attributes.Weightage,
                    Status: null,
                    Is_Fatal: subparam.attributes.Is_Fatal,
                    Fatal_If_Repeated: subparam?.attributes?.Fatal_If_Repeated,
                    Fatal_Count: subparam?.attributes?.Fatal_Count,
                    Fatal_Duration: subparam?.attributes?.Fatal_Duration,
                    L2_Dropdown_Value: '',
                    L2_Dropdown_Options:
                        subparam?.attributes?.Audit_L_2_Options?.data.map(
                            el => el.attributes.title
                        ),
                    remark: '',
                    Has_Remark: subparam?.attributes?.Has_Remark,
                    Critical_Type: subparam?.attributes?.Critical_Type,
                    Response_Type:
                        subparam?.attributes?.Response_Type ??
                        'Yes, Partial, No, N/A',
                    Verbatim: null
                };
            }
            apiFormatSingle.data.Report_Data[paramName] = [paramObj];
        }
        return apiFormatSingle;
    };

    function updateStatus(param, subparam, value, type = 'Status') {
        let apiFormatBody = apiFormatRef.current;
        try {
            const paramTobeUpdated = apiFormatBody?.data?.Report_Data
                ? apiFormatBody.data.Report_Data[param][0][subparam]
                : apiFormatBody[param][0][subparam];
            paramTobeUpdated[type] = value;
        } catch (e) {
            throw new Error('Invalid Param or Subparam');
        }
        return apiFormatBody;
    }

    function getDepartmentName(departmentTitle) {
        if (!departmentTitle) {
            return { toolTipTitle: "", title: "" };
        }
        const cleanedTitle = departmentTitle.replace(/- Autogenerated/gi, '');
        const parts = cleanedTitle.split(' / ');
        const toolTipTitle = parts.join(' / ');
        const title = parts.length > 2 ? `... / ${parts[parts.length - 1]}` : parts.join(' / ');
        return {title, toolTipTitle };
    };
      

    const getComments = async (showToast = false, id = false) => {
        try {
            const reportId = id ? id : clonedlocation?.state?.auditData?.id
            const response = await client.get(
                '/audit-reports/get-comments/' + reportId
            );
            setComments(response.data);
            if (showToast) {
                successNotify('Comment added successfully');
            }
        } catch (e) {
            if (showToast) {
                ErrorNotify('Could not add a comment, Please try again later!');
            }
        }
    };

    const [fatalvalue, setFatalValue] = useState({});

    function getFirstNHorizontalLayouts(uiSchema, n) {
        if (
            !uiSchema ||
            !uiSchema.elements ||
            uiSchema.elements.length === 0 ||
            n <= 0
        ) {
            return []; // Return an empty array if the input is invalid
        }

        return uiSchema.elements
            .slice(0, n)
            .filter(element => element.type === 'HorizontalLayout');
    }

    function handleFatalEarnedScore(subparam, e) {
        const { Is_Fatal, Description } = subparam.attributes;
        if (!Is_Fatal) return;
        const button = e.target.value;
        let fatalvalueNew = {};

        if (Is_Fatal) {
            if (button == 'No') {
                fatalvalueNew = { ...fatalvalue, [Description]: true };
            } else {
                fatalvalueNew = { ...fatalvalue, [Description]: false };
            }
            setFatalValue(fatalvalueNew);
        }
    }

    function handleSubParameterClick(row) {
        const res = _.get(aiResponseTime.current, [row], null);
        if (res?.start && res?.end) {
            setCurrAudioTime(res?.start)
            setSubParamTime({...res, time:Date.now()});
        }
    }

    // QA side while initial perform audit
    const process = async () => {
        if (!clonedlocation?.state?.department) {
            navigate(`/o/${orgStrId}/perform-audit/list`);
            return;
        }
        setLoading(true);
        const response = await client.get(
            `/audit-forms?filters[Department]=${clonedlocation?.state?.department.id}&filters[Is_Active]=true&populate[0]=Created_By.username&populate[1]=Department&sort=Version:desc&populate[2]=Audit_Parameters.Audit_Subparameters&populate[3]=Audit_Parameters.Audit_Subparameters.Audit_L_2_Options`
        );
        if (response?.data?.length === 0) {
            return;
        }
        setDepartmentBasePrompt(
            response?.data[0]?.attributes?.Department?.data?.attributes
                ?.Department_Base_Prompt
        );
        setSummarizerBasePrompt(
            response?.data[0]?.attributes?.Department?.data?.attributes
                ?.Summarizer_Base_Prompt
        );
        setDepartment(
            response?.data[0]?.attributes?.Department?.data?.attributes
        );
        const { Custom_Fields_UI, Custom_Fields_Schema } = lodashGet(
            response,
            'data.[0].attributes'
        );
        for (const key in Custom_Fields_Schema?.properties) {
            if (Custom_Fields_Schema.properties.hasOwnProperty(key)) {
                Custom_Fields_Schema.properties[key].handleChange = function (
                    e
                ) {
                    handleChangeField(e);
                };
                Custom_Fields_Schema.properties[key].showError = false;
            }
        }
        if (Custom_Fields_UI && Custom_Fields_Schema) {
            setSchema(Custom_Fields_Schema);
            setUischemaCopy(Custom_Fields_UI);
            const firstNHorizontalLayouts = getFirstNHorizontalLayouts(
                Custom_Fields_UI,
                2
            );
            setUischema({
                type: 'VerticalLayout',
                elements: firstNHorizontalLayouts,
            });
        }
        setLoading(false);
        let temp = JSON.parse(JSON.stringify(response.data));
        temp = temp.map(auditsheet => {
            auditsheet.attributes.Audit_Parameters.data = _.orderBy(
                auditsheet.attributes.Audit_Parameters.data,
                ['id'],
                ['asc']
            );
            return auditsheet;
        });
        setParameter(temp[0]?.attributes?.Audit_Parameters?.data);

        setAuditFormId(response.data[0].id);
        const apiFormatData = formatAsApiData(temp[0]);
        apiFormatRef.current = apiFormatData;

        const paramData = temp[0].attributes.Audit_Parameters.data;
        const paramsToBeSet = [];
        let rowNum = 0;
        const criticalTotals = { ...criticalScoreTemplate };
        const responsesTemp = [];

        paramData.map(param => {
            const { Audit_Subparameters, Parameter_Name } = param?.attributes;
            const everyFatal = Audit_Subparameters?.data.every(item => {
                return item?.attributes.Is_Fatal === true;
            });

            Audit_Subparameters.data.map((subparam, idx) => {
                const {
                    Weightage,
                    Is_Fatal,
                    Subparameter_Name,
                    Has_L2_Dropdown = false,
                    Audit_L_2_Options = [],
                    Critical_Type,
                    Response_Type = 'Yes, Partial, No, N/A',
                } = subparam.attributes || {};
                responsesTemp.push({
                    type: '',
                    weight: Weightage,
                    fatal: Is_Fatal,
                    critical: Critical_Type,
                });
                const obj = {};
                if (idx == 0) {
                    obj.param = {
                        className: everyFatal ? 'fatal' : '',
                        value: Parameter_Name,
                        rowspan: Audit_Subparameters.data.length,
                    };
                } else {
                    obj.param = { value: null };
                }
                const slNo = rowNum;
                obj.subparam = {
                    className: Is_Fatal ? 'fatal' : '',
                    value: (
                        <>
                            {<p id={`subparam-${rowNum}`} onClick={()=>handleSubParameterClick(slNo)}>{Subparameter_Name}</p>}{' '} 
                            {criticalArray.includes(Critical_Type) && (
                                <span className='complince_tag'>
                                    {Critical_Type}
                                </span>
                            )}
                        </>
                    ),
                };

                obj.weightage = {
                    className: Is_Fatal ? 'fatal' : '',
                    value: Weightage,
                };
                const criticalType = Critical_Type;
                if (criticalType) {
                    criticalTotals[criticalType] += Weightage;
                }
                obj.status = {
                    isComponent: true,
                    className: 'td_status ' + (Is_Fatal ? 'fatal' : ''),
                    value: ButtonType(
                        Response_Type ?? 'Yes, Partial, No, N/A',
                        Parameter_Name,
                        subparam,
                        rowNum,
                        handleResponse,
                        handleFatalEarnedScore,
                        false,
                        auditStatus,
                        isParameterEditable
                    ),
                };

                obj.reason = {
                    className: Is_Fatal ? 'fatal' : '',
                    value: (
                        <select
                            onChange={e =>
                                updateCommentAndDropdown(
                                    Parameter_Name,
                                    Subparameter_Name,
                                    e.target.value,
                                    'L2_Dropdown_Value'
                                )
                            }
                            name={`reason${rowNum}`}
                            className={`form-control ${!Has_L2_Dropdown ? 'disabled' : ''}`}
                            disabled={!Has_L2_Dropdown}
                            id={'reason' + rowNum}
                        >
                            <option key={rowNum + 'Select'} value=''>
                                {Has_L2_Dropdown ? 'Select' : ''}
                            </option>
                            {Has_L2_Dropdown && Audit_L_2_Options?.data.map(el => {
                                    const title = el?.attributes?.title;
                                    return (
                                        <option
                                            key={title}
                                            value={title}
                                        >
                                            {title}
                                        </option>
                                    );
                                })}
                        </select>
                    ),
                };

                obj.remarks = {
                    className: Is_Fatal ? 'fatal' : '',
                    value: (
                        <input
                            onChange={e =>
                                updateCommentAndDropdown(
                                    Parameter_Name,
                                    Subparameter_Name,
                                    e.target.value,
                                    'remark'
                                )
                            }
                            className={`form-control ${!subparam.attributes.Has_Remark ? 'disabled' : ''}`}
                            disabled={!subparam.attributes.Has_Remark}
                            name={'remark' + rowNum}
                            id={'remark' + rowNum}
                        ></input>
                    ),
                };

                if (response?.data[0]?.attributes?.Department?.data?.attributes?.Ai_Audit_Verbatim_Model) {
                    if (!verbatimColumnRef.current) {
                        verbatimColumnRef.current = true;
                    }
                    obj.verbatim = {
                        className: Is_Fatal ? 'fatal' : '',
                        value: (
                            <input
                                className="form-control"
                                name={'verbatim' + rowNum}
                                id={'verbatim' + rowNum}
                                value={""}
                                readOnly
                            />
                        ),
                    };
                }
                
                paramsToBeSet.push(obj);
                rowNum++;
            });
        });
        setAuditSheetData(paramsToBeSet);
        setResponses([...responsesTemp]);
        responseRef.current = [...responsesTemp]
    };

    function addPositionToData(data) {
        const newData = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                newData[key] = data[key].map((innerObj, index) => {
                    let count = 1;
                    for (const key in innerObj) {
                        innerObj[key] = { ...innerObj[key], pos: count };
                        count++;
                    }
                    return innerObj;
                });
            }
        }
        return newData;
    }

    function isCurrentDateBeforeGivenDate(date) {
        const givenDate = new Date(date);
        const currentDate = new Date();
    
        return currentDate <= givenDate;
    }

    // Agent side while viewing audit and rebuttal side as well
    const processReport = async () => {
        if (Object.keys(paramReportData).length == 0) {
            navigate(`/o/${orgStrId}/view-my-audit/list`);
            return;
        }
        setLoading(true);
        setAuditFormId(null);
        let paramData = paramReportData;
        apiFormatRef.current = paramData;
        const paramsToBeSet = [];
        const responsesData = [];
        const criticalTotals = { Customer: 0, Business: 0, Compliance: 0 };
        let rowNum = 0;
        let totalWeightage = 0;
        let subParamArr = [];
        paramData = checkAllFatal(paramData);
        const EditablebeforeTatForButton = EditablebeforeTat.current;
        for (const paramName in paramData) {
            const param = paramData[paramName];
            let idx = 0;
            for (const subparamName in param[0]) {
                const subparam = param[0][subparamName];

                const obj = {};
                const criticalType = subparam.Critical_Type;
                if (criticalType) {
                    criticalTotals[criticalType] += subparam.Weightage;
                }
                if (idx == 0) {
                    obj.param = {
                        className: param[1].everyFatal ? 'fatal' : '',
                        value: paramName,
                        rowspan: Object.keys(param[0]).length,
                    };
                } else {
                    obj.param = { value: null };
                }
                const slNo = rowNum;
                obj.subparam = {
                    className: subparam.Is_Fatal ? 'fatal' : '',
                    value: (
                        <>
                            {<p id={`subparam-${rowNum}`} onClick={()=>handleSubParameterClick(slNo)}>{subparamName}</p>}{' '}
                            {criticalArray.includes(
                                subparam?.Critical_Type
                            ) && (
                                <span className='complince_tag'>
                                    {subparam?.Critical_Type}
                                </span>
                            )}
                        </>
                    ),
                };
                responsesData[rowNum] = {
                    type: subparam.Status,
                    weight: subparam.Weightage,
                    fatal: subparam.Is_Fatal,
                    critical: subparam?.Critical_Type,
                };

                obj.weightage = {
                    className: subparam.Is_Fatal ? 'fatal' : '',
                    value: subparam.Weightage,
                };
                totalWeightage += subparam.Weightage;
                obj.status = {
                    isComponent: true,
                    className:
                        'td_status ' + (subparam.Is_Fatal ? 'fatal' : ''),
                    value: ButtonType(
                        subparam.Response_Type ?? 'Yes, Partial, No, N/A',
                        paramName,
                        { ...subparam, Subparameter_Name: subparamName },
                        rowNum,
                        handleResponse,
                        handleFatalEarnedScore,
                        true,
                        auditStatus,
                        isParameterEditable,
                        EditablebeforeTatForButton
                    ),
                };

                obj.reason = {
                    className: subparam.Is_Fatal ? 'fatal' : '',
                    value: (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                !isParameterEditable ? (
                                    <Tooltip>
                                        {subparam.L2_Dropdown_Value}
                                    </Tooltip>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            <select
                                name={`reason${rowNum}`}
                                id={'reason' + rowNum}
                                className={`form-control ${(subparam?.L2_Dropdown_Options?.length === 0)
                                        ? "disabled"
                                        : (auditStatus === "qaView" && EditablebeforeTat.current)
                                            ? ""
                                            : !isParameterEditable
                                                ? "disabled"
                                                : ""
                                    }`}

                                disabled={
                                    (subparam?.L2_Dropdown_Options?.length === 0)
                                        ? true
                                        : (auditStatus === "qaView" && EditablebeforeTat.current)
                                            ? false
                                            : !isParameterEditable
                                                ? true
                                                : false
                                }
                                onChange={e =>
                                    updateCommentAndDropdown(
                                        paramName,
                                        subparamName,
                                        e.target.value,
                                        'L2_Dropdown_Value'
                                    )
                                }
                                defaultValue={subparam.L2_Dropdown_Value}
                            >
                                <option key={rowNum + 'Select'} value=''>
                                    {isParameterEditable &&
                                        subparam?.L2_Dropdown_Options &&
                                        subparam?.L2_Dropdown_Options.length > 0
                                        ? 'Select'
                                        : subparam.L2_Dropdown_Value}
                                </option>
                                {subparam?.L2_Dropdown_Options &&
                                    subparam?.L2_Dropdown_Options.map(
                                        option => {
                                            return (
                                                <option
                                                    key={rowNum + 'Select'}
                                                    value={option}
                                                >
                                                    {option}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </OverlayTrigger>
                    ),
                };

                obj.remarks = {
                    className: subparam.Is_Fatal ? 'fatal' : '',
                    value: (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                !isParameterEditable ? (
                                    <Tooltip>{subparam.remark}</Tooltip>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            <input
                                className='form-control '
                                disabled={
                                    !subparam?.Has_Remark ? true : (auditStatus == "qaView" && EditablebeforeTat.current) ? false : (
                                        !isParameterEditable)
                                }
                                onChange={e =>
                                    updateCommentAndDropdown(
                                        paramName,
                                        subparamName,
                                        e.target.value,
                                        'remark'
                                    )
                                }
                                defaultValue={subparam.remark}
                                name={'remark' + rowNum}
                                id={'remark' + rowNum}
                            ></input>
                        </OverlayTrigger>
                    ),
                };

                if (department.Ai_Audit_Verbatim_Model && (EditablebeforeTat.current || subparam.Verbatim)) {
                    if (!verbatimColumnRef.current) {
                        verbatimColumnRef.current = true;
                    }
                    obj.verbatim = {
                        className: subparam.Is_Fatal ? 'fatal' : '',
                        value: (
                            <input
                                className="form-control"
                                name={'verbatim' + rowNum}
                                id={'verbatim' + rowNum}
                                value={subparam?.Verbatim}
                                readOnly
                                disabled = {!EditablebeforeTat.current}
                            />
                        ),
                    };
                    subParamArr[slNo] = {start: subparam?.start, end: subparam?.end}
                }
                paramsToBeSet.push(obj);
                rowNum++;
                idx++;
            }
        }
        aiResponseTime.current = subParamArr
        setLoading(false);
        setResponses(responsesData); // processReport
        responseRef.current = responsesData;
        setAuditSheetData(paramsToBeSet);
    };

    useMemo(() => {
        if (auditStatus == 'initialAudit') {
            process();
            document.title = `Perform Audit - ${ticketId} | Omind`;
        } else {
            if (auditStatus != 'qaView') getComments();
            document.title = `${mode} Audit Report - ${ticketId} | Omind`;
        }

        if (auditStatus == 'qaView') {
            qaViewReport(ticketId);
        } else {
            setParamReportData(paramReportDataSorted);
            setDepartmentToolTip(getDepartmentName(clonedlocation?.state?.department?.Display_Name || 
                clonedlocation?.state?.auditData?.CRM_Import?.Department?.Display_Name));
            setDepartment(clonedlocation?.state?.department||clonedlocation?.state?.auditData?.CRM_Import?.Department);
        }
    }, [ticketId]);

    useMemo(() => {
        if (showFor == 'Agent' || auditStatus == 'qaView') {
            processReport();
        }
    }, [paramReportData, isParameterEditable]);

    const handleSubmit = async () => {
        if (submitFunctionSingleCall.current) {
            return;
        }
        submitFunctionSingleCall.current = true;
        SetButtonDisable(true);
        setShowModal(false);
        const body = apiFormatRef.current;
        body.data.Audit_Form = auditFormId;
        body.data.Comment = comment;
        body.data.Custom_Fields_Data = {
            data: payloadData?.current,
            Custom_Fields_Schema: schema,
            Custom_Fields_UI: uiSchema,
        };
        body.data.Possible_Score = possibleScore;
        body.data.Earned_Score = earnedScore;
        body.data.Report_Data = addPositionToData(body.data.Report_Data);
        body.data.Report_Data_Order = Object.keys(body.data.Report_Data);
        body.data.Ai_Driven_Audit = aiDrivenAudit;
        const { data } = await client.post(`/audit-reports`, body);
        successNotify('Audit completed successfull!');
        navigate(`/o/${orgStrId}/perform-audit/list`);
    };

    const handleSave = async () => {
        if (saveFunctionSingleCall.current) {
            return;
        }

        saveFunctionSingleCall.current = true;
        SetButtonDisable(true);
        setShowModal(false);
        await client.post(
            `/audit-reports/update-report/${auditStatus == 'qaView' ? qaViewAuditIdRef.current : clonedlocation?.state?.auditData?.id}`,
            {
                status: (auditStatus == 'qaView') ? 'Audit Done' : mode == "Edit" ? "Audit Edited" : "Accepted",
                Report_Data: apiFormatRef.current,
                Ai_Driven_Audit: aiDrivenAudit,
                Custom_Fields_Data: {
                    data: payloadData?.current,
                    Custom_Fields_Schema: schema,
                    Custom_Fields_UI: uiSchemaCopy,
                },
            }
        );
        successNotify('Audit updated successfully');
        await delay(2000);
        navigate(-1);
    };

    const handleChangeField = e => {
        
        if (e?.target) {
            const { name, value } = e?.target;
            payloadData.current = { ...payloadData.current, [name]: value };
        } else {
            const name = e.name;
            payloadData.current = {
                ...payloadData.current,
                [name]: e?.value?.[0],
            };
        }
        const disableSaveButton = !_.isEmpty(jsonsDataRef.current)
            ? _.isEqual(jsonsDataRef.current, payloadData.current) &&
              _.isEqual(responseRef.current, cloneResponseData)
            : _.isEqual(responseRef.current, cloneResponseData);
        if (disableSaveButton) {
            SetSaveButtonDisable(true);
        } else {
            if (saveButtonDisable == true) {
                SetSaveButtonDisable(false);
            }
        }
    };

    function handleViewMore(viewMore) {
        if (viewMore) {
            const updatedUISchema = getFirstNHorizontalLayouts(uiSchemaCopy, 2);
            setUischema({ type: 'VerticalLayout', elements: updatedUISchema });
        } else {
            setUischema(uiSchemaCopy);
        }
        setViewMore(!viewMore);
    }

    async function validateCustomFields(e) {
        setUischema(() => uiSchemaCopy);
        if (!viewMore) {
            setViewMore(() => true);
        }
        const customForm = document.getElementById('customForm');
        const data = document.querySelectorAll('#require');
        for (let i = 0; i < data.length; i++) {
            const val = data[i].value;
            if (!val) {
                customForm.classList.add('was-validated');
                return;
            }
        }
        await delay(500);
        if (customForm) {
            customForm.classList.add('was-validated');
            if (customForm.checkValidity()) {
                customForm.classList.remove('was-validated');
                return true;
            } else {
                let firstInvalidElement = null;
                for (let i = 0; i < customForm.elements.length; i++) {
                    if (!customForm.elements[i].checkValidity()) {
                        if (!firstInvalidElement) {
                            firstInvalidElement = customForm.elements[i];
                            break;
                        }
                    }
                }
                firstInvalidElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                e.preventDefault();
                return false;
            }
        }
        return true;
    }

    function removeKeys(obj) {
        const keysToRemove = [
            'overall_sentiment',
            'agent_sentiment',
            'prospect_sentiment',
            'prospect_summary',
            'agent_summary',
            'overall_summary',
            'feedback',
        ];

        const newObj = { ...obj };

        keysToRemove.forEach(key => {
            delete newObj[key];
        });

        return newObj;
    }

    async function handleTranscription(result) {
        const payload = {
            callStartTime: result?.start_time,
            callEndTime: result?.end_time,
            transcript: result?.transcript?.map(el => {
                return {
                    from:el?.speaker === "Agent" ? el?.speaker?.toLowerCase() : "prospect",
                    message: el?.utterance,
                };
            }),
        };
        const transcription = result.transcript;
        let conversationParagraph = '';
        transcription.forEach(transcript => {
            conversationParagraph += `${[transcript.speaker]}: ${transcript.utterance} ${transcript.start && transcript.end ? ` (start : ${transcript.start} - end : ${transcript.end}) ` : ''}\n`;
        });
        setConversationString(conversationParagraph);
        setConversation(transcription);
        setMetadata(`Call Duration: ${result.duration ? result.duration : ''}`);
        if (result?.topics?.length > 0) {
            setTopic(result.topics)
        }
        if (result?.intents?.length > 0) {
            setIntent(result.intents)
        }
        if (conversationParagraph) {
            transcriptionSummary(conversationParagraph);
        }
        if(department?.Omind_API){
            try {
            const res = await axios.post(kpiBaseRoot, payload, {
                headers: {
                    'X-Client': organisation?.organisationName?.includes("Conduent") ? "Conduent" : ""
                }
            });
            const finalKpi = removeKeys(res?.data);
            setKpi(finalKpi);
        } catch {
        
        }}
        
        return;
    }
    const splitStringLongLines = (inputString, maxLength = 40000) => {
        const chunks = [];
        let currentChunk = '';

        const lines = inputString.split('\n');
        for (const line of lines) {
            if (currentChunk.length + line.length <= maxLength) {
                currentChunk += line + '\n';
            } else {
                chunks.push(currentChunk);
                currentChunk = line + '\n';
            }
        }

        if (currentChunk) {
            chunks.push(currentChunk);
        }

        return chunks;
    };

    async function transcriptionSummary(transcription) {
        if (summaryFunctionCall.current == true) {
            return;
        }
        summaryFunctionCall.current = true;
        aiAuditButton.current = true;
        setSummaryLoader(true);
        if (
            (!conversationSummary ||
                !conversationSentiment ||
                !agentSentiment ||
                !customerSentiment || !sentimentSummary) &&
            crm_import_id
        ) {
            try {
                const crmDataForTicket = await client.get(
                    `/crm-imports/${crm_import_id}`
                );
                if (
                    crmDataForTicket?.data &&
                    crmDataForTicket?.data?.attributes?.Conversation_Summary &&
                    crmDataForTicket?.data?.attributes?.Sentiment &&
                    crmDataForTicket?.data?.attributes?.Agent_Sentiment &&
                    crmDataForTicket?.data?.attributes?.Customer_Sentiment&&
                    crmDataForTicket?.data?.attributes?.Sentiment_Summary
                ) {
                    setTranscriptionDetailedSummary(
                        crmDataForTicket?.data?.attributes?.Conversation_Summary
                    );
                    setSentiment(crmDataForTicket?.data?.attributes?.Sentiment);
                    setAgentSentiment(crmDataForTicket?.data?.attributes?.Agent_Sentiment);
                    setCustomerSentiment(crmDataForTicket?.data?.attributes?.Customer_Sentiment);
                    setSentimentSummary(crmDataForTicket?.data?.attributes?.Sentiment_Summary);
                    aiAuditButton.current = false;
                    setSummaryLoader(false);
                    return;
                }
            } catch (error) {}
        }
        const chunks = splitStringLongLines(transcription);
        let transcriptionSummary = '';
        let transcriptionSentiment = '';
        let transcriptionagentSentiment = '';
        let transcriptioncustomerSentiment = '';
        let transcriptionSentimentSummary = '';
        try {
            if (
                !conversationSummary ||
                !conversationSentiment ||
                !agentSentiment ||
                !customerSentiment ||
                !sentimentSummary 
            ) {
                for (const chunk of chunks) {
                    const response = await client.post(
                        '/crm-imports/summarizer',
                        {
                            data: {
                                basePrompt: summarizerBasePrompt,
                                summary: transcriptionSummary,
                                conversation: chunk || '',
                                department: department?.Department_Code
                            },
                        }
                    );
                    if (response?.success) {
                        transcriptionSummary = response?.data?.data?.summary;
                        transcriptionSentiment =
                            response?.data?.data?.sentiment;
                        transcriptionagentSentiment =
                            response?.data?.data?.agentSentiment;
                        transcriptioncustomerSentiment =
                            response?.data?.data?.customerSentiment;
                        transcriptionSentimentSummary =
                            response?.data?.data?.sentimentSummary;
                    }
                }
                try {
                    await client.post('/crm-imports/addCallSummary', {
                        data: {
                            crmId: crm_import_id,
                            TicketId: ticketId,
                            summary: transcriptionSummary || '',
                            sentiment: transcriptionSentiment,
                            agentSentiment: transcriptionagentSentiment,
                            customerSentiment: transcriptioncustomerSentiment,
                            sentimentSummary: transcriptionSentimentSummary
                        },
                    });
                } catch (error) {}
                setTranscriptionDetailedSummary(transcriptionSummary);
                setSentiment(transcriptionSentiment);
                setAgentSentiment(transcriptionagentSentiment);
                setCustomerSentiment(transcriptioncustomerSentiment);
                setSentimentSummary(transcriptionSentimentSummary);
                
            } else if (
                conversationSummary &&
                conversationSentiment &&
                agentSentiment &&
                customerSentiment &&
                sentimentSummary
            ) {
                setTranscriptionDetailedSummary(conversationSummary);
                setSentiment(conversationSentiment);
                setAgentSentiment(agentSentiment);
                setCustomerSentiment(customerSentiment);
                setSentimentSummary(sentimentSummary);
            }
        } catch (error) {
            aiAuditButton.current = false;
            setSummaryLoader(false);
        }
        aiAuditButton.current = false;
        setSummaryLoader(false);
    }

    function isWithinTokenLimit(inputString, tokenLimit = 40000) {
        const encoder = getEncoding('cl100k_base');
        const tokenCount = encoder.encode(inputString).length;
        return tokenCount <= tokenLimit;
    }

    const showCritical = useMemo(() => {
        return _.isEmpty(paramReportData)
            ? parameter.some(el => el.attributes.Critical)
            : checkCriticalType(paramReportData);
    }, [paramReportData, parameter]);

    useEffect(() => {
        let active = document.querySelector('#scrollToHere');
        if (active && containerRef.current) {
            const container = containerRef.current;
            const activePosition = active?.offsetTop - container?.offsetTop;

            container.scrollTo({
                top: activePosition,
                behavior: 'smooth',
            });
        }
    }, [currAudioTime, subParamTime]);

    const [search, setSearch] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const activeElementRefs = useRef([]);
    
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);
      return () => clearTimeout(timeoutId);
    }, [search]);
    
    useEffect(() => {
      if (activeElementRefs.current[currentIndex]) {
        activeElementRefs.current[currentIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, [currentIndex, debouncedSearch]);
    
    const processMatches = (text, searchTerm) => {
        try {
            if (!searchTerm) return { text: [text], matches: [] };
            const regex = new RegExp(`(${searchTerm})`, "gi");
            const matches = [];
            const splitText = text.split(regex);
            let index = 0;
            splitText.forEach((chunk) => {
                if (regex.test(chunk)) {
                    matches.push(index);
                }
                index++;
            });
            return { text: splitText, matches };
        } catch (error) {
            return { text: [text], matches: [] };
        }
    };
    
    const highlightText = (splitText, matches, searchTerm, currentIdx) => {
      return splitText.map((chunk, index) => {
        if (matches.includes(index)) {
          const isCurrent = matches.indexOf(index) === currentIdx;
          return (
            <span
              key={index}
              className={isCurrent ? "current-highlight" : "highlight"}
            >
              {chunk}
            </span>
          );
        }
        return chunk;
      });
    };
    
    const allMatches = conversation.reduce(
      (acc, { utterance }, i) => {
        const { text, matches } = processMatches(utterance, debouncedSearch);
        acc.texts.push(text);
        acc.matches.push(
          ...matches.map((idx) => ({ idx, conversationIndex: i }))
        );
        return acc;
      },
      { texts: [], matches: [] }
    );
    
    const totalMatches = allMatches.matches.length;
    
    const handleNavigation = (direction) => {
      setCurrentIndex((prev) =>
        direction === "next"
          ? (prev + 1) % totalMatches
          : (prev - 1 + totalMatches) % totalMatches
      );
    };

    const [searchView, setSearchView] = useState(false);
    
    return (
        <>
            <div className='page-wrapper perform_audit_wrapper'>
                <div className='content pb-0'>
                    <div className='content_wrap_style'>
                        <div className='content_wrap_comman'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='breadcrumb_box'>
                                        <Breadcrumb>
                                            <Breadcrumb.Item as='li' href='/'>
                                                <img
                                                    src={imagePath(
                                                        '/images/home_icon_pagig.svg'
                                                    )}
                                                />
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                as='li'
                                                linkAs={Link}
                                                linkProps={{
                                                    to:
                                                        showFor == 'Agent' &&
                                                        mode != 'Edit'
                                                            ? viewAuditHome
                                                            : performAuditHome,
                                                }}
                                                aria-current='page'
                                            >
                                                {showFor == 'Agent' &&
                                                mode != 'Edit'
                                                    ? 'View My Audits'
                                                    : 'Perform Audit'}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                as='li'
                                                active
                                                aria-current='page'
                                            >
                                                {ticketId}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className='page_title_box'>
                                        <h3 className='page_title'>
                                            {'Audit Sheet'}
                                        </h3>
                                    </div>

                                    <div className='ticket_id mb-3'>
                                        <h3>
                                            <strong className='font-weight-500'>
                                                Ticket/Call ID:
                                            </strong>{' '}
                                            {ticketId?.toUpperCase()}
                                        </h3>
                                    </div>
                                </div>
                                <div className='col-lg-9'>
                                    <div className='row mb-3'>
                                        <div className='col-md-3'>
                                            <div className='audit_details_view row text-start ms-2'>
                                                <div className='col'>
                                                    {
                                                        toolTipHOC(departmentToolTip?.title, departmentToolTip?.toolTipTitle, true, 'bottom')
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <div className='wave_audio_box'>
                                                <div className='vidget_comman_inner'>
                                                    <div className=''>
                                                        <div className='audio_player card_border'>
                                                            <Audio
                                                                AudioUrl={
                                                                    audioUrl
                                                                }
                                                                config={
                                                                    clonedlocation
                                                                        ?.state
                                                                        ?.department
                                                                        ?.Audio_Config ||
                                                                    clonedlocation
                                                                        ?.state
                                                                        ?.auditData
                                                                        ?.CRM_Import
                                                                        ?.Department
                                                                        ?.Audio_Config
                                                                }
                                                                ticketId={
                                                                    ticketId
                                                                }
                                                                transcription={

                                                                    conversation
                                                                }
                                                                showDualWave={
                                                                    conversation?.length && conversation?.[0]?.start
                                                                        ? true
                                                                        : false
                                                                }
                                                                isLoading = {isLoading}
                                                                setCurrAudioTime = {setCurrAudioTime}
                                                                currAudioTime = {currAudioTime}
                                                                subParamTime = {subParamTime}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col-lg-3 col-md-4 col-sm-12'>
                                    {/* accordian start */}
                                    <div className='audit_trans_accordian'>
                                        <Accordion
                                            defaultActiveKey={['2']}
                                            alwaysOpen
                                        >
                                            <Accordion.Item
                                                style={{
                                                    display:
                                                    (transcription || Object.keys(preloadedTranscription).length > 0) &&
                                                        (auditStatus == 'qaView'|| department?.Ai_Audit || showFor === 'Agent')
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                                eventKey='2'
                                            >
                                                <Accordion.Header>
                                                    {' '}
                                                    <div className='audit_vidget_head d-flex justify-content-between w-100 align-items-center'>
                                                        <h5 className='font-weight-500'>
                                                            Transcription
                                                        </h5>
                                                        <buttton onClick={(e) => {
                                                            setSearchView(!searchView)
                                                            e.preventDefault();
                                                            e.stopPropagation()
                                                        }} className={`me-2 search_view_btn ${searchView ? "active" : ""}`}>
                                                            <img src='/images/search_icon.svg' />
                                                        </buttton>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        {searchView && <div className='search_match_box' >
                                                            <input
                                                                type="text"
                                                                placeholder="Search messages"
                                                                value={search}
                                                                onChange={(e) => {
                                                                    setSearch(e.target.value);
                                                                    setCurrentIndex(0);
                                                                }}
                                                                className='form-control'
                                                            />
                                                            <div className='ms-1 match_count'>{totalMatches == 0 ? 0 : currentIndex + 1}:{totalMatches}</div>
                                                            <button className='ms-1 me-1 p-1 btn_transparent search_find_btn' onClick={() => handleNavigation("prev")}>↑</button>
                                                            <button className='p-1 btn_transparent search_find_btn' onClick={() => handleNavigation("next")}>↓</button>
                                                        </div>}
                                                        <div className="audit_transcription scrollbar_style" ref={containerRef}>
                                                            {conversation?.length > 0 &&
                                                                conversation.map(({ speaker, utterance, start, end }, i) => {
                                                                    const { text, matches } = processMatches(utterance, debouncedSearch);
                                                                    const conversationMatches = allMatches.matches.filter(
                                                                        (m) => m.conversationIndex === i
                                                                    );
                                                                    const currentMatchIdx = conversationMatches.findIndex(
                                                                        (m) => m === allMatches.matches[currentIndex]
                                                                    );

                                                                    const isActive =
                                                                        (currAudioTime >= start && currAudioTime <= end) ||
                                                                        (Math.trunc(subParamTime?.start) <= Math.trunc(start) &&
                                                                            Math.trunc(subParamTime?.end) >= Math.trunc(end));

                                                                    const className =
                                                                        speaker.toLowerCase() === "customer"
                                                                            ? "audit_transcript_bx t_cus"
                                                                            : "audit_transcript_bx t_agent";

                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            ref={(el) => {
                                                                                if (el && currentMatchIdx !== -1) {
                                                                                    activeElementRefs.current[currentIndex] = el;
                                                                                }
                                                                            }}
                                                                            speaker={`Conversation ${i}`}
                                                                            className={className}
                                                                            style={{
                                                                                background: isActive ? "#d8ffea" : "white",
                                                                                borderRadius: "5px",
                                                                                padding: "4px 6px",
                                                                            }}
                                                                            id={isActive ? "scrollToHere" : ""}
                                                                        >
                                                                            <p>
                                                                                <span className="t_name">{speaker}: </span>
                                                                                {highlightText(text, matches, debouncedSearch, currentMatchIdx)}
                                                                            </p>
                                                                           
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                style={{
                                                    display:
                                                        (transcription || Object.keys(preloadedTranscription).length > 0) &&
                                                            (auditStatus == 'qaView'|| department?.Ai_Audit || showFor === 'Agent')
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                                eventKey='0'
                                            >
                                                <Accordion.Header>
                                                    <div className='audit_vidget_head'>
                                                        <h5 className='font-weight-500'>
                                                            Summary
                                                        </h5>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='audit_transcription scrollbar_style'>
                                                        {transcriptionDetailedSummary?.length >
                                                        0 ? (
                                                            <p>
                                                                {
                                                                    transcriptionDetailedSummary
                                                                }
                                                            </p>
                                                        ) : (
                                                            <div>
                                                                {conversation?.length >
                                                                    0 &&
                                                                    conversation.map(
                                                                        (
                                                                            e,
                                                                            i
                                                                        ) => {
                                                                            return i ==
                                                                                1 ? (
                                                                                <div
                                                                                    key={`shimmerMessage ${i}`}
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}
                                                                                >
                                                                                    summarization
                                                                                    In
                                                                                    progress...
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        height: '10px',
                                                                                    }}
                                                                                    className='shimmer'
                                                                                    key={`shimmer ${i}`}
                                                                                >
                                                                                    &nbsp;
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                style={{
                                                    display:
                                                    (transcription || Object.keys(preloadedTranscription).length > 0 ) &&
                                                        (auditStatus == 'qaView' || department?.Ai_Audit || showFor === 'Agent')
                                                            ? 'block'
                                                            : 'none',
                                                }}>
                                                <Accordion.Header className='sentimate_anlys_div-'>
                                                    <div
                                                        className='audit_vidget_head'
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            gap: '10',
                                                        }}
                                                    >
                                                        <h5 className='font-weight-500'>
                                                            Overall Sentiment 
                                                        </h5>
                                                        <h5 className='sentimate_result'>
                                                            {!customersentiment
                                                                ? ''
                                                                : customersentiment ==
                                                                    'Positive'
                                                                  ? `${customersentiment} 😊`
                                                                  : customersentiment ==
                                                                      'Negative'
                                                                    ? `${customersentiment} 😡`
                                                                    : `${customersentiment} 😐`}
                                                        </h5>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>{sentimentSummaryParagraph ? sentimentSummaryParagraph : ""}</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                  style={{
                                                    display:
                                                    (intent.length > 0)
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                                eventKey='4'
                                            >
                                                <Accordion.Header>
                                                    <div className='audit_vidget_head'>
                                                        <h5 className='font-weight-500'>
                                                            Intents Detected
                                                        </h5>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    <div className='metricsStatus'>
                                                        <p className='two_column_title'>
                                                            <span>Intents</span>{' '}
                                                            <span>Count</span>
                                                        </p>
                                                    </div>
                                                    <div className='audit_transcription scrollbar_style'>
                                                        {intent?.length > 0 && intent.map((e, i) => (
                                                            <div key={`intent-${i}`} className='trans_list'>
                                                                <span>{e.value}</span>
                                                                <span>{e.count}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                style={{
                                                    display:
                                                    (topic.length > 0)
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                                eventKey='5'
                                            >
                                                <Accordion.Header>
                                                    <div className='audit_vidget_head'>
                                                        <h5 className='font-weight-500'>
                                                            Topic Detected
                                                        </h5>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='audit_transcription scrollbar_style'>
                                                        {topic?.length > 0 && topic.map((e, i) => (
                                                            <div key={`topic-${i}`} className='trans_list'>
                                                                <span>{e.value}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {/* ------- */}
                                            <Accordion.Item
                                                style={{
                                                    display: (Object.keys(kpi).length > 0)?'block':'none',
                                                }}
                                                eventKey='3'
                                            >
                                                <Accordion.Header>
                                                    <div className='audit_vidget_head'>
                                                        <h5 className='font-weight-500'>
                                                            Performance Metrics
                                                        </h5>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {Object.keys(kpi).length > 0 ? (
                                                        <>
                                                            <div className='metricsStatus'>
                                                                <p className='two_column_title'>
                                                                    <span>Metrics</span>{' '}
                                                                    <span>Agent Performance</span>
                                                                </p>
                                                            </div>
                                                            <div className='audit_transcription scrollbar_style'>
                                                                {Object.entries(kpi).map(([key, value]) => (
                                                                        <div
                                                                        key={key}
                                                                            style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            marginBottom: '5px',
                                                                        }}
                                                                    >
                                                                            <span>
                                                                                {key
                                                                                .replace(/_/g, ' ')
                                                                                .replace(/\b\w/g, char =>
                                                                                            char.toUpperCase()
                                                                                    )}
                                                                            </span>
                                                                        <span>{value}</span>
                                                                        </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <p>Loading performance metrics...</p>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    </div>

                                    {!mode && (
                                        <div className='audit_details_vidget vidget_comman'>
                                            <div className='vidget_comman_inner'>
                                                <div className='audit_vidget_head'>
                                                    <h4 className='font-weight-500'>
                                                        Comment
                                                    </h4>
                                                </div>
                                                <hr></hr>
                                                <div className='mt-1'>
                                                    <div className='permission_comment'>
                                                        <textarea
                                                            onChange={e =>
                                                                saveComment(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className='form-control'
                                                            placeholder='Share what you loved or what could have been better...'
                                                        ></textarea>
                                                        <div className='comment_custamizer'>
                                                            <div className='chat_send'>
                                                                <ul>
                                                                    <li>
                                                                        <button
                                                                            className='btn comman_btn'
                                                                            onClick={async e => {
                                                                                const checkValidate =
                                                                                    await validateCustomFields(
                                                                                        e
                                                                                    );
                                                                                if (
                                                                                    !checkValidate
                                                                                ) {
                                                                                    return;
                                                                                }
                                                                                const form =
                                                                                    document.getElementById(
                                                                                        'form-audit'
                                                                                    );
                                                                                form.classList.add(
                                                                                    'was-validated'
                                                                                );
                                                                                if (
                                                                                    form.checkValidity()
                                                                                ) {
                                                                                    document
                                                                                        .getElementById(
                                                                                            'form-audit'
                                                                                        )
                                                                                        .classList.remove(
                                                                                            'was-validated'
                                                                                        );
                                                                                    setShowModal(
                                                                                        true
                                                                                    );
                                                                                    SetButtonDisable(
                                                                                        false
                                                                                    );
                                                                                } else {
                                                                                    const form =
                                                                                        document.getElementById(
                                                                                            'form-audit'
                                                                                        );
                                                                                    let firstInvalidElement =
                                                                                        null;
                                                                                    for (
                                                                                        let i = 0;
                                                                                        i <
                                                                                        form
                                                                                            .elements
                                                                                            .length;
                                                                                        i++
                                                                                    ) {
                                                                                        if (
                                                                                            !form.elements[
                                                                                                i
                                                                                            ].checkValidity()
                                                                                        ) {
                                                                                            if (
                                                                                                !firstInvalidElement
                                                                                            ) {
                                                                                                firstInvalidElement =
                                                                                                    form
                                                                                                        .elements[
                                                                                                        i
                                                                                                    ];
                                                                                                break;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    firstInvalidElement?.scrollIntoView(
                                                                                        {
                                                                                            behavior:
                                                                                                'smooth',
                                                                                            block: 'center',
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            Send
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {mode && (
                                        <RebuttalComments
                                            mode={mode}
                                            showFor={showFor}
                                            auditStatusView={auditStatus}
                                            setIsParameterEditable={
                                                setIsParameterEditable
                                            }
                                            isParameterEditable={
                                                isParameterEditable
                                            }
                                            orgStrId={orgStrId}
                                            reportId={
                                                clonedlocation?.state?.auditData?.id
                                            }
                                            getComments={getComments}
                                            allComments={comments}
                                            title={'Rebuttal Raised'}
                                            Report_Data={
                                                mode == 'Edit'
                                                    ? paramReportData
                                                    : undefined
                                            }
                                            currentStatus={
                                                clonedlocation?.state?.auditData
                                                    ?.Audit_Status_Current
                                            }
                                        />
                                    )}
                                </div>
                                <div className='col-lg-9 col-md-8 col-sm-12'>
                                    <div
                                        className='text-end'
                                        style={{
                                            visibility:
                                                (auditStatus == 'qaView' && EditablebeforeTat.current && department?.Ai_Audit && conversation?.length > 0) ? 'visible' : 
                                                department?.Ai_Audit && conversation.length > 0 && ['initialAudit'].includes(auditStatus) 
                                                ? 'visible' : 'hidden'
                                        }}
                                    >
                                        <div className='ai_assist_btn_div'>
                                            {summaryLoader ? (
                                                <>
                                                    {!qaReport?.Ai_Driven_Audit && (
                                                        <>
                                                            {' '}
                                                            <div className='ai_tooltip'>
                                                                Summarization in
                                                                progress
                                                            </div>
                                                            <button
                                                                onClick={
                                                                    handleAIAudit
                                                                }
                                                                disabled={
                                                                    aiAuditButton.current ==
                                                                    true
                                                                }
                                                                className='btn comman_btn ai_assist_btn'>
                                                                AI Assist
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {!qaReport?.Ai_Driven_Audit && (
                                                        <button
                                                            onClick={
                                                                handleAIAudit
                                                            }
                                                            disabled={
                                                                aiAuditButton.current ==
                                                                true
                                                            }
                                                            className='btn comman_btn ai_assist_btn'>
                                                            AI Assist
                                                        </button>
                                                    )}
                                                    <div
                                                        className='btn__spinner'
                                                        style={{
                                                            display: auditLoader
                                                                ? 'block'
                                                                : 'none',
                                                        }}
                                                    ></div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className='cus_all_datatype'>
                                        <Form
                                            className='form_style_comman'
                                            id='customForm'
                                        >
                                            <JsonForms
                                                schema={schema}
                                                uischema={uiSchema}
                                                data={
                                                    showFor == 'Agent' &&
                                                    mode == 'View'
                                                        ? jsonsDataRef.current
                                                        : payloadData.current
                                                }
                                                renderers={renderers}
                                                cells={materialCells}
                                                required={true}
                                                visible={false}
                                            />
                                            {uiSchemaCopy?.elements?.length >
                                                2 && (
                                                <div className='custom_more mb-3 text-end'>
                                                    <p>
                                                        <span
                                                            onClick={() =>
                                                                handleViewMore(
                                                                    viewMore
                                                                )
                                                            }
                                                            className='font-weight-500 more_less'
                                                        >
                                                            {viewMore
                                                                ? 'View Less'
                                                                : 'View More'}
                                                        </span>
                                                    </p>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4 agent_score_card_box'>
                                            <Card className='mb-3'>
                                                <Card.Body>
                                                    <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                        <div className='score_media align-items-center'>
                                                            <div className='dash_content'>
                                                                <h3>
                                                                    Earned Score
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <h2>
                                                            {hasTrueValue(
                                                                fatalvalue
                                                            )
                                                                ? 0
                                                                : earnedScore}
                                                        </h2>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-lg-4 agent_score_card_box'>
                                            <Card className='mb-3'>
                                                <Card.Body>
                                                    <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                        <div className='score_media align-items-center'>
                                                            <div className='dash_content'>
                                                                <h3>
                                                                    Possible
                                                                    Score
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <h2>{possibleScore}</h2>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='col-lg-4 agent_score_card_box'>
                                            <Card className='mb-3'>
                                                <Card.Body>
                                                    <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                        <div className='score_media align-items-center'>
                                                            <div className='dash_content'>
                                                                <h3>
                                                                    Total Score
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <h2>
                                                            {hasTrueValue(
                                                                fatalvalue
                                                            )
                                                                ? 0
                                                                : percent}
                                                            %
                                                        </h2>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>

                                    {showCritical && (
                                        <div className='row'>
                                            <div className='col-lg-4 agent_score_card_box'>
                                                <Card className='mb-3'>
                                                    <Card.Body>
                                                        <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                            <div className='score_media align-items-center'>
                                                                <div className='dash_content'>
                                                                    <h3>
                                                                        Business
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <h2>{`${criticalScoreCalculated['Business']} %`}</h2>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='col-lg-4 agent_score_card_box'>
                                                <Card className='mb-3'>
                                                    <Card.Body>
                                                        <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                            <div className='score_media align-items-center'>
                                                                <div className='dash_content'>
                                                                    <h3>
                                                                        Customer
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <h2>{`${criticalScoreCalculated['Customer']} %`}</h2>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='col-lg-4 agent_score_card_box'>
                                                <Card className='mb-3'>
                                                    <Card.Body>
                                                        <div className='d-flex justify-content-between align-items-center agent_score_card'>
                                                            <div className='score_media align-items-center'>
                                                                <div className='dash_content'>
                                                                    <h3>
                                                                        Compliance
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <h2>{`${criticalScoreCalculated['Compliance']} %`}</h2>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    )}

                                    <div className='permission_table'>
                                        <div className='text-end mb-3'>
                                            {((showFor == 'Agent' &&
                                                mode == 'Edit') || EditablebeforeTat.current) && (
                                                <Button
                                                    className='btn comman_btn savebuttonrebuttal'
                                                    disabled={
                                                        (!saveButtonDisable || !saveButtonDisableByRemarksAndDropDown)
                                                            ? false
                                                            : true
                                                    }
                                                        onClick={
                                                            handleShowModal
                                                        }
                                                    >
                                                    Save
                                                </Button>
                                            )}
                                        </div>
                                        <div className='table_style_comman table_chat_audit'>
                                            <form id='form-audit'>
                                                <div className='audit_status_width_only'>
                                                    <CustomDataTable
                                                        addClass={
                                                            'table-bordered'
                                                        }
                                                        alternate={true}
                                                        isLoading={isLoading}
                                                        options={{
                                                             columns : [
                                                                { title: 'Parameters' },
                                                                { title: 'Sub-Parameters' },
                                                                { title: 'Weightage' },
                                                                { title: verbatimColumnRef.current && department.Ai_Audit_Verbatim_Model ? 'Verbatim Compliance' : 'Status' },
                                                                { title: 'Reason' },
                                                                { title: 'Remarks' },
                                                                ...(verbatimColumnRef.current && department.Ai_Audit_Verbatim_Model ? [{ title: 'Script Accuracy' }] : [])
                                                            ],
                                                            data: auditSheetData,
                                                        }}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <Modal
                                        show={showModal}
                                        onHide={() => setShowModal(false)}
                                        backdrop='static'
                                        keyboard={false}
                                        className='alret_modal'
                                    >
                                        <Modal.Header closeButton>
                                            {/* <Modal.Title>Modal title</Modal.Title> */}
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='alret_modal_body'>
                                                <div className='text-center'>
                                                    {/* <h3>Are you sure!</h3> */}
                                                    <h4>
                                                        Are you sure you want to
                                                        submit the audit?
                                                    </h4>
                                                </div>
                                                <div className='alret_modal_bottom'>
                                                    <Button
                                                        variant='link'
                                                        className='bordered_btn link_btn-'
                                                        onClick={() =>
                                                            setShowModal(false)
                                                        }
                                                    >
                                                        No
                                                    </Button>
                                                    <Button
                                                        variant='primary'
                                                        className='ms-3'
                                                        disabled={buttonDisable}
                                                        onClick={
                                                            ((mode == RC_EDIT &&
                                                            showFor == RC_AGENT) || EditablebeforeTat.current)
                                                                ? handleSave
                                                                : handleSubmit
                                                        }
                                                    >
                                                        Yes, Accept
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PerformAudit;

function checkAllFatal(Report_Data) {
    // if (EditablebeforeTat.current && !apiFormatBody?.data) {
        // Report_Data = Report_Data.data?.Report_Data;
    // }
    if(!Report_Data){
        return {};
    }
    const temp = JSON.parse(JSON.stringify(Report_Data));
    for (const section in temp) {
        const subArray = temp[section];
        const allFatal = Object.values(subArray[0]).every(
            item => item?.Is_Fatal === true
        );
        subArray.splice(1, 0, { everyFatal: allFatal });
    }
    return temp;
}

function hasTrueValue(obj) {
    for (const key in obj) {
        if (obj[key] === true) {
            return true;
        }
    }
    return false;
}

function transformDataForObjectMatch(inputData) {
    const result = [];
    Object.values(inputData).forEach(data => {
        data.forEach(item => {
            Object.values(item).forEach(details => {
                result.push({
                    type: details.Status,
                    weight: details.Weightage,
                    fatal: details.Is_Fatal,
                    critical: details?.Critical_Type,
                });
            });
        });
    });
    return result;
}

function checkCriticalType(obj) {
    if (_.isEmpty(obj)) {
        return false;
    }
    let flag = false;
    for (const key in obj) {
        const subParam = obj[key][0];
        for (const subKey in subParam) {
            if (
                _.includes(
                    ['Business', 'Customer', 'Compliance'],
                    subParam[subKey]?.Critical_Type
                )
            ) {
                flag = true;
                return flag;
            }
        }
    }
    return flag;
}
